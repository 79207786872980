import "../../../styles/Home.scss";
import {ListItemIcon, ListItemText, MenuItem} from "@mui/material";
import * as React from "react";
import {useNavigate} from "react-router-dom";
import {getIcon, IconName} from "../../../service/iconFinderService";
import {getFormattedContent} from "../../../service/ContentService";
import {useGlobalState} from "../../../state/GlobalState";

export function SVRMenuButton() {
  const [currentLanguage] = useGlobalState("language");
  const navigation = useNavigate();
  return (
    <>
      <MenuItem
        onClick={() => {
          navigation("/supplemental-vehicle-request");
        }}
        data-testid={"svrMenuButton"}>
        <ListItemIcon>
          <img
            src={getIcon(IconName.SVR)}
            className="menu-button-icons"
            alt={"SVR menu button"}
          />
        </ListItemIcon>
        <ListItemText>
          {getFormattedContent(
            "supplementalVehicleRequestMenuLabel",
            currentLanguage
          )}
        </ListItemText>
      </MenuItem>
    </>
  );
}

import "../../styles/Home.scss";
import fordLogo from "../../images/Logos/fordOval.png";
import modeleLogo from "../../images/Logos/modeleLogo.png";
import {Navigation} from "../navigation/Navigation";
import {ConfirmSubmissionModal} from "../vehicleSubmission/ConfirmSubmissionModal";
import * as React from "react";
import {Greetings} from "./Greetings";
import "../../styles/Header.scss";
import {HomeLink} from "./HomeLink";
import {getSkin, Skin} from "../../service/SkinService";
import {LincolnHeader} from "../lincoln/LincolnHeader";
import {useRecoilValue} from "recoil";
import ContactForm from "../../state/ContactForm";

export function Header() {
  const skin = getSkin();

  switch (skin) {
    case Skin.LPIVW:
      return <LincolnHeader />;
    case Skin.MEW:
      return <FordHeader logo={<ModelELogo />} />;
    default:
      return <FordHeader logo={<FordLogo />} />;
  }
}

function ModelELogo() {
  return <img className={"header-logo"} src={modeleLogo} alt={"Modele logo"} />;
}

function FordLogo() {
  return <img className={"header-logo"} src={fordLogo} alt={"Ford logo"} />;
}

const FordHeader = (props: {logo: React.ReactElement}) => {
  const confirmationVisible = useRecoilValue(ContactForm);
  let logo = props.logo;
  return (
    <>
      <header className={"header"}>
        <a href={"/"} aria-label={"logo-link-to-home"}>
          {logo}
        </a>
        <span className={"header-content"}>
          <Greetings />
          <div className={"home"}>
            <HomeLink />
          </div>
          <Navigation />
        </span>
      </header>
      <div>{confirmationVisible && <ConfirmSubmissionModal />}</div>
    </>
  );
};

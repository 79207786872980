import {ApiUrls} from "../enums/ApiUrls";
import {getHttpClient} from "./API";
import {AxiosResponse} from "axios";

export interface SupplementalVehicle {
  salesCode: string;
  vehicles: OfferedVehicle[];
}

export interface OfferedVehicle {
  count: number;
  vehicleLine: string;
  code: string;
  body: string;
}

export function createSupplementalVehicle(
  request: SupplementalVehicle
): Promise<AxiosResponse> {
  const httpClient = getHttpClient();
  return httpClient.post(ApiUrls.createSupplementalVehicles, request);
}

export async function getSupplementalVehiclesCSV() {
  return (await getHttpClient().get(ApiUrls.supplementalVehiclesCSV)).data;
}

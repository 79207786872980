import {useState} from "react";
import {
  getLPIVWDisclaimerAccepted,
  putLPIVWDisclaimer,
} from "../../service/LincolnDisclaimerService";
import Modal from "react-modal";
import {getContent} from "../../service/ContentService";
import {useGlobalState} from "../../state/GlobalState";

export function LincolnCVWDisclaimer() {
  const [currentLanguage] = useGlobalState("language");
  const [isCheckboxDisabled, setIsCheckboxDisabled] = useState(true);
  const [shouldShowCvwDisclaimer, setShouldShowCvwDisclaimer] = useState(false);

  let [isOpen, setIsOpen] = useState(true);

  useState(() => {
    getLPIVWDisclaimerAccepted().then((response) => {
      if (response.data === false) {
        setShouldShowCvwDisclaimer(true);
      }
    });
  });

  return (
    <>
      {shouldShowCvwDisclaimer && (
        <Modal isOpen={isOpen} ariaHideApp={false}>
          <div data-brand={"lincoln"}>
            <h3 className={"modal-header"}>
              2024 Nautilus Produced Import Vehicle Wholesale
            </h3>
            <h3 className={"modal-header"}>Allocation Methodology Notice</h3>
            <div className={"modal-description"}>
              {getContent("allocationMethodology", currentLanguage)}
            </div>
            <div className="fds-m--t-3 center-content">
              <input
                className={"fds-m--r-1"}
                onChange={() => {
                  setIsCheckboxDisabled(!isCheckboxDisabled);
                }}
                type={"checkbox"}
              />
              {getContent("disclaimerAcceptanceLabel", currentLanguage)}
            </div>
            <div className="fds-m--t-3 center-content">
              <button
                className={"fmc-button fds-m--b-2"}
                disabled={isCheckboxDisabled}
                onClick={() => {
                  putLPIVWDisclaimer().then(() => {
                    setIsOpen(false);
                  });
                }}>
                {getContent("continueLabel", currentLanguage)}
              </button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}

import React from "react";
import "../../styles/LoadingModal.scss";

export function LoadingModal() {
  return (
    <div className="loading-modal-container">
      <div className="loading-modal">
        <div className="fmc-activity-circle" data-testid="loading-modal">
          <div className="fmc-activity-circle__progress"></div>
        </div>
        <div>LOADING...</div>
      </div>
    </div>
  );
}

import "../../../styles/Home.scss";
import {ListItemIcon, ListItemText, MenuItem} from "@mui/material";
import * as React from "react";
import {useNavigate} from "react-router-dom";
import {getSkin, Skin} from "../../../service/SkinService";
import {getIcon, IconName} from "../../../service/iconFinderService";
import {getContent} from "../../../service/ContentService";
import {useGlobalState} from "../../../state/GlobalState";

export function OrderHistoryMenuButton() {
  const navigation = useNavigate();
  const [currentLanguage] = useGlobalState("language");

  return (
    <>
      <MenuItem
        data-testid={"orderHistoryMenuButton"}
        onClick={() => {
          navigation("/history");
        }}>
        <ListItemIcon>
          <img
            src={getIcon(IconName.OrderHistory)}
            className="menu-button-icons"
            alt={"order history menu button"}
          />
        </ListItemIcon>
        <ListItemText>
          {getSkin() === Skin.MEW
            ? "Stocking Request History"
            : getContent("orderHistoryMenuButton", currentLanguage)}
        </ListItemText>
      </MenuItem>
    </>
  );
}

import React, {useCallback, useEffect, useState} from "react";
import "../../styles/Timer.scss";
import {useGlobalState} from "../../state/GlobalState";
import {CountdownCircleTimer} from "react-countdown-circle-timer";
import {OfferWindow} from "../../models/OfferWindow";
import {getOfferWindow} from "../../service/TimerService";
import {TIME_CONVERSIONS} from "../../enums/Timer";
import {OfferingReview} from "../orderGrid/OfferingReview";
import {getSkin, Skin} from "../../service/SkinService";
import {getContent} from "../../service/ContentService";

export function Timer() {
  const [offerWindow, setOfferWindow] = useState<OfferWindow>({
    startTime: "",
    endTime: "",
    reallocationEndTime: "",
  });
  const [initialRemainingTime, setInitialRemainingTime] = useState<number>(0);
  const [totalDuration, setTotalDuration] = useState<number>(0);
  const [currentLanguage] = useGlobalState("language");
  const [offeringWindowOngoing, setOfferingWindowOngoing] = useGlobalState(
    "offeringWindowOngoing"
  );
  const [reallocationPeriodOngoing, setReallocationPeriodOngoing] =
    useGlobalState("reallocationPeriodOngoing");

  const updateTimeRemaining = useCallback(
    (offerWindow: OfferWindow) => {
      let remainingTime: React.SetStateAction<number>;
      if (offerWindow.endTime) {
        remainingTime = Math.floor(
          convertMsToSeconds(
            offerWindow.endTime.getTime() - new Date().getTime()
          )
        );
      } else {
        remainingTime = 0;
      }
      setInitialRemainingTime(remainingTime);
    },
    [setInitialRemainingTime]
  );

  const updateTotalDuration = useCallback(
    (offerWindow: OfferWindow) => {
      let offerWindowDuration = offerWindow.startTime
        ? convertMsToSeconds(
            offerWindow.endTime.getTime() - offerWindow.startTime.getTime()
          )
        : 0;
      setTotalDuration(offerWindowDuration);
    },
    [setTotalDuration]
  );

  useEffect(() => {
    getOfferWindow()
      .then((newOfferWindow) => {
        setOfferWindow(newOfferWindow);

        setOfferingWindowOngoing(
          newOfferWindow.startTime <= Date.now() &&
            newOfferWindow.endTime > Date.now()
        );

        updateTimeRemaining(newOfferWindow);
        updateTotalDuration(newOfferWindow);

        setReallocationPeriodOngoing(
          newOfferWindow.startTime <= Date.now() &&
            newOfferWindow.reallocationEndTime > Date.now()
        );
      })
      .catch();
  }, [
    setOfferingWindowOngoing,
    setReallocationPeriodOngoing,
    updateTimeRemaining,
    updateTotalDuration,
  ]);

  const getMinutesRemaining = (seconds: number): number => {
    return Math.floor(
      (seconds % TIME_CONVERSIONS.secondsPerHour) /
        TIME_CONVERSIONS.minutesPerHour
    );
  };

  const getHoursRemaining = (seconds: number): number => {
    return Math.floor(seconds / TIME_CONVERSIONS.secondsPerHour);
  };

  const convertMsToSeconds = (milliseconds: number): number => {
    return milliseconds / TIME_CONVERSIONS.millisecondsPerSecond;
  };

  const countdownPeriod = 24 * 60 * 60;
  let showCountdownTimer: boolean = initialRemainingTime <= countdownPeriod;

  const OfferingWindowClosed = () => {
    return (
      <div data-testid="offerWindowNotYetStarted">
        <h6>{getContent("offerNotYetStarted", currentLanguage)}</h6>
        <div data-testid={"start-date"}>
          {getContent("offerStarts", currentLanguage)}{" "}
          {offerWindow.startTime === 0
            ? "N/A"
            : offerWindow.startTime.toLocaleString(
                [currentLanguage === "fr" ? "fr-CA" : "en-US"],
                {
                  month: "long",
                  day: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                  timeZoneName: "short",
                }
              )}{" "}
        </div>
      </div>
    );
  };

  const OfferingWindowOpen = () => {
    return (
      <>
        <div className={"timer-text"}>
          <div>
            <h6 className="vehicle-offering-header">
              {getContent("vehicleOfferingLabel", currentLanguage)}
            </h6>
          </div>
          <div data-testid={"end-date"}>
            {getContent("offerEndsMessage", currentLanguage)}
            {offerWindow.endTime === 0
              ? "N/A"
              : offerWindow.endTime.toLocaleString(
                  [currentLanguage === "fr" ? "fr-CA" : "en-US"],
                  {
                    month: "long",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    timeZoneName: "short",
                  }
                )}
          </div>
          <OfferingReview />
        </div>
        {showCountdownTimer && totalDuration > 0 && (
          <div title={"Countdown Circle Timer"}>
            <CountdownCircleTimer
              key={totalDuration}
              size={110}
              initialRemainingTime={initialRemainingTime}
              isPlaying
              duration={totalDuration}
              colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
              colorsTime={[
                totalDuration,
                totalDuration / 2,
                totalDuration / 10,
                0,
              ]}
              onComplete={() => {
                setOfferingWindowOngoing(false);
              }}>
              {({remainingTime}) => {
                return (
                  <span className={"time-remaining"}>
                    <span data-testid={"hours"}>
                      {getHoursRemaining(remainingTime) < 10 ? "0" : ""}
                      {getHoursRemaining(remainingTime)}h
                    </span>
                    <span data-testid={"minutes"}>
                      {getMinutesRemaining(remainingTime) < 10 ? "0" : ""}
                      {getMinutesRemaining(remainingTime)}m
                    </span>
                  </span>
                );
              }}
            </CountdownCircleTimer>
          </div>
        )}
      </>
    );
  };

  const ReallocationPeriodOpen = ({
    offerWindow,
  }: {
    offerWindow: OfferWindow;
  }) => {
    return (
      <div className={"timer-text reallocation-timer-text"}>
        <div>
          <h6 className="vehicle-offering-header">
            {getContent("ReallocationPeriodLabel", currentLanguage)}
          </h6>
        </div>
        <div data-testid={"reallocation-end-time"}>
          {getContent("LimitedActionPeriod", currentLanguage)}{" "}
          <strong>
            {offerWindow.reallocationEndTime.toLocaleString(
              [currentLanguage === "fr" ? "fr-FR" : "en-EN"],
              {
                month: "long",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                timeZoneName: "short",
              }
            )}{" "}
          </strong>
        </div>
      </div>
    );
  };

  const DisplayTimerConditionally = () => {
    if (offeringWindowOngoing && reallocationPeriodOngoing) {
      return <OfferingWindowOpen />;
    }

    if (!offeringWindowOngoing && reallocationPeriodOngoing) {
      return <ReallocationPeriodOpen offerWindow={offerWindow} />;
    }

    return <OfferingWindowClosed />;
  };

  const skin = getSkin();

  return (
    <div
      data-testid={"countdown-timer"}
      className={
        skin === Skin.LPIVW
          ? "timer-box lincoln-table-box-header"
          : "timer-box table-box-header"
      }>
      <DisplayTimerConditionally />
    </div>
  );
}

import lincolnLogo from "../../images/Logos/lincolnLogo.svg";
import {Greetings} from "../header/Greetings";
import {HomeLink} from "../header/HomeLink";
import {Navigation} from "../navigation/Navigation";
import "../../styles/lincoln/header.scss";
import * as React from "react";
import {LanguageMenu} from "../languageMenu/LanguageMenu";

export function LincolnHeader() {
  return (
    <div className={"header-lincoln"}>
      <div className={"lincoln-header-box"}>
        <a href={"/"} aria-label={"logo-link-to-home"}>
          <img
            className={"header-logo-lincoln"}
            src={lincolnLogo}
            alt={"Lincoln logo"}
          />
        </a>
        <span className={"header-content"}>
          <Greetings />
          <LanguageMenu />
          <div className={"home"}>
            <HomeLink />
          </div>
          <Navigation />
        </span>
      </div>
    </div>
  );
}

import {getHttpClient} from "./API";
import {ApiUrls} from "../enums/ApiUrls";

export async function getOrderHistory(salesCode: string) {
  const response = await getHttpClient().get(
    ApiUrls.modelEOrder + `?salesCode=${salesCode}`
  );

  return response.data;
}

import {DisplayableSelectionStatus} from "../enums/DisplayableSelectionStatus";
import {Optionality} from "../enums/Optionality";

export interface FormattedVehicleLine {
  vehicleLineName: string;
  formattedOrders: FormattedOrder[];
}

export type OrderFeature = {
  wersCode: string;
  description: string;
  optionality: Optionality;
};

export enum BackendSelectionStatus {
  Accepted = "Accepted",
  Rejected = "Rejected",
  NO_ACTION = "no_action",
  Reallocated = "Reallocated",
}

export interface Order {
  vin: string;
  body: OrderFeature;
  salesCode: string;
  code: OrderFeature;
  engine: OrderFeature;
  paint: OrderFeature;
  trim: OrderFeature;
  transmission: string;
  wheelbase: string;
  options: OrderFeature[];
  packages: OrderFeature[];
  selection: BackendSelectionStatus;
  lastModifiedBy: string;
  lastModifiedSelectionDate: string;
  vehicleLine: string;
}

export interface FormattedOrder {
  vin: string;
  key: string;
  body: OrderFeature;
  salesCode: string;
  code: OrderFeature;
  engine: OrderFeature;
  paint: OrderFeature;
  trim: OrderFeature;
  options: OrderFeature[];
  packages: OrderFeature[];
  selection: BackendSelectionStatus;
  formattedSelection: DisplayableSelectionStatus | "";
  lastModifiedBy: string;
  lastModifiedSelectionDate: string;
  vehicleLine: string;
}

function isDisplayableSelection(
  selection: string
): selection is DisplayableSelectionStatus {
  return Object.values(DisplayableSelectionStatus).includes(
    selection as DisplayableSelectionStatus
  );
}

function getFormattedSelection(order: Order): DisplayableSelectionStatus | "" {
  if (isDisplayableSelection(order.selection)) {
    return DisplayableSelectionStatus[order.selection];
  }
  return "";
}

export const orderToFormattedOrder = (order: Order): FormattedOrder => ({
  vin: order.vin,
  key: order.vin,
  body: order.body,
  salesCode: order.salesCode,
  code: order.code,
  engine: order.engine,
  paint: order.paint,
  trim: order.trim,
  options: order.options,
  packages: order.packages,
  selection: order.selection,
  formattedSelection: getFormattedSelection(order),
  lastModifiedBy: order.lastModifiedBy,
  lastModifiedSelectionDate: order.lastModifiedSelectionDate,
  vehicleLine: order.vehicleLine,
});

export interface VehicleLineWersDescriptions {
  catalogId: string;
  country: string;
  vehicleLine: string;
  descriptions: Map<string, Map<string, string>>;
}

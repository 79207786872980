import {OrderHistoryBySalesCode} from "../../components/orderHistory/OrderHistoryBySalesCode";
import {useGlobalState} from "../../state/GlobalState";
import {OrderHistoryByRegion} from "../../components/orderHistory/OrderHistoryByRegion";
import {getContent} from "../../service/ContentService";
import React from "react";
import {getSkin, Skin} from "../../service/SkinService";
import {OrderHistoryList} from "../../components/orderHistory/OrderHistoryList";

export function OrderHistory() {
  const [currentLanguage] = useGlobalState("language");
  const [hasReadAllPermissions] = useGlobalState("hasReadAllPermissions");

  function showOrderHistory() {
    if (getSkin() === Skin.MEW) {
      return <OrderHistoryList />;
    } else if (hasReadAllPermissions) {
      return <OrderHistoryByRegion />;
    } else {
      return <OrderHistoryBySalesCode />;
    }
  }

  return (
    <div>
      <div className={"portal-header-history"}>
        <label className="portal-name">
          {getContent("headerLabel", currentLanguage)}
        </label>
      </div>

      <h3 className={"page-header"}>
        {getContent("orderHistoryPageSubject", currentLanguage)}
      </h3>

      <p className={"page-subject"}>
        {getContent("orderHistoryPageDescription", currentLanguage)}
      </p>

      {showOrderHistory()}
    </div>
  );
}

import {getContent} from "../../service/ContentService";
import {useGlobalState} from "../../state/GlobalState";

export function LincolnFAQ() {
  const [currentLanguage] = useGlobalState("language");

  return (
    <div data-brand={"lincoln"}>
      <div className={"content-width"}>
        <p className={"lincoln-question  lincoln-common-faq-row"}>
          <span className={"lead-letter"}>
            {getContent("QuestionFirstLetter", currentLanguage)}:{" "}
          </span>
          <span className={"faq-text"}>
            {getContent("Question1Label", currentLanguage)}
          </span>
        </p>
        <p className={"answer lincoln-common-faq-row"}>
          <span className={"lead-letter"}>
            {getContent("AnswerFirstLetter", currentLanguage)}:{" "}
          </span>
          <span className={"faq-text"}>
            {getContent("Answer1Label", currentLanguage)}
          </span>
        </p>

        <p className={"lincoln-question  lincoln-common-faq-row"}>
          <span className={"lead-letter"}>
            {getContent("QuestionFirstLetter", currentLanguage)}:{" "}
          </span>
          <span className={"faq-text"}>
            {getContent("Question2Label", currentLanguage)}
          </span>
        </p>
        <p className={"answer lincoln-common-faq-row"}>
          <span className={"lead-letter"}>
            {getContent("AnswerFirstLetter", currentLanguage)}:{" "}
          </span>
          <span className={"faq-text"}>
            {getContent("Answer2Label", currentLanguage)}
          </span>
        </p>
        <p className={"lincoln-question  lincoln-common-faq-row"}>
          <span className={"lead-letter"}>
            {getContent("QuestionFirstLetter", currentLanguage)}:{" "}
          </span>
          <span className={"faq-text"}>
            {getContent("Question3Label", currentLanguage)}
          </span>
        </p>
        <p className={"answer lincoln-common-faq-row"}>
          <span className={"lead-letter"}>
            {getContent("AnswerFirstLetter", currentLanguage)}:{" "}
          </span>
          <span className={"faq-text"}>
            {getContent("Answer3Label", currentLanguage)}
          </span>
        </p>
        <p className={"lincoln-question  lincoln-common-faq-row"}>
          <span className={"lead-letter"}>
            {getContent("QuestionFirstLetter", currentLanguage)}:{" "}
          </span>
          <span className={"faq-text"}>
            {getContent("Question4Label", currentLanguage)}
          </span>
        </p>
        <p className={"answer lincoln-common-faq-row"}>
          <span className={"lead-letter"}>
            {getContent("AnswerFirstLetter", currentLanguage)}:{" "}
          </span>
          <span className={"faq-text"}>
            {getContent("Answer4Label", currentLanguage)}
          </span>
        </p>
        <p className={"lincoln-question  lincoln-common-faq-row"}>
          <span className={"lead-letter"}>
            {getContent("QuestionFirstLetter", currentLanguage)}:{" "}
          </span>
          <span className={"faq-text"}>
            {getContent("Question5Label", currentLanguage)}
          </span>
        </p>
        <p className={"answer lincoln-common-faq-row"}>
          <span className={"lead-letter"}>
            {getContent("AnswerFirstLetter", currentLanguage)}:{" "}
          </span>
          <span className={"faq-text"}>
            {getContent("Answer5Label", currentLanguage)}
          </span>
        </p>
        <p className={"lincoln-question  lincoln-common-faq-row"}>
          <span className={"lead-letter"}>
            {getContent("QuestionFirstLetter", currentLanguage)}:{" "}
          </span>
          <span className={"faq-text"}>
            {getContent("Question6Label", currentLanguage)}
          </span>
        </p>
        <p className={"answer lincoln-common-faq-row"}>
          <span className={"lead-letter"}>
            {getContent("AnswerFirstLetter", currentLanguage)}:{" "}
          </span>
          <span className={"faq-text"}>
            {getContent("Answer6Label", currentLanguage)}
          </span>
        </p>
      </div>
    </div>
  );
}

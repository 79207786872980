import ReactTooltip from "react-tooltip";
import "../../styles/RebillingTooltip.scss";
import {getContent} from "../../service/ContentService";
import InfoToolTip from "../../images/infoTooltip.png";
import {useGlobalState} from "../../state/GlobalState";

export function RRTooltip(props: {contentId: string; image: boolean}) {
  const [currentLanguage] = useGlobalState("language");
  const tooltipMessage = getContent(props.contentId, currentLanguage);

  const tooltipHasContent = () =>
    !(
      tooltipMessage === "" || tooltipMessage === props.contentId.toUpperCase()
    );

  function getImg() {
    if (props.image) {
      return (
        <img className={"tool-tip-img"} src={InfoToolTip} alt={"InfoToolTip"} />
      );
    } else return null;
  }

  return (
    <>
      {tooltipHasContent() && (
        <div className={"tool-tip"} data-testid={"rr-tooltip"}>
          <ReactTooltip
            role={"rr-tooltip"}
            place={"bottom"}
            effect={"solid"}
            multiline={true}
            id={"rr-tooltip"}
            class={"react-tooltip"}
          />
          <span
            className={"tool-tip"}
            data-testid={"tool-tip-span"}
            data-tip={tooltipMessage}
            data-for={"rr-tooltip"}>
            {getImg()}
          </span>
        </div>
      )}
    </>
  );
}

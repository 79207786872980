export enum Skin {
  CVW = "cvw",
  MEW = "mew",
  RR = "rr",
  LPIVW = "lpivw",
}

export function getSkin(): Skin {
  if (
    window.location.hostname.startsWith("configuredvehiclewholesale.lincoln")
  ) {
    return Skin.LPIVW;
  }

  if (window.location.hostname.startsWith("configuredvehiclewholesale")) {
    return Skin.CVW;
  } else if (window.location.hostname.startsWith("evdealerstockingportal")) {
    return Skin.MEW;
  } else if (
    window.location.hostname.startsWith(
      "producedimportvehiclewholesale.lincoln"
    ) ||
    window.location.hostname.startsWith("pivw.lincoln")
  ) {
    return Skin.LPIVW;
  } else {
    return Skin.RR;
  }
}

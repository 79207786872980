import {getContent, getFormattedContent} from "../../service/ContentService";

export function ModelEFAQ() {
  return (
    <div className={"content-width"}>
      <p className={"question  common-faq-row"}>
        <span className={"lead-letter"}>
          {getContent("QuestionFirstLetter")}:{" "}
        </span>
        <span className={"faq-text"}>{getContent("Question1Label")}</span>
      </p>
      <div className={"answer common-faq-row"}>
        <span className={"lead-letter"}>
          {getContent("AnswerFirstLetter")}:{" "}
        </span>
        <span className={"faq-text"}>
          {getFormattedContent("Answer1Label")}
        </span>
      </div>
      <p className={"question  common-faq-row"}>
        <span className={"lead-letter"}>
          {getContent("QuestionFirstLetter")}:{" "}
        </span>
        <span className={"faq-text"}>{getContent("Question2Label")}</span>
      </p>
      <div className={"answer common-faq-row"}>
        <span className={"lead-letter"}>
          {getContent("AnswerFirstLetter")}:{" "}
        </span>
        <span className={"faq-text"}>
          {getFormattedContent("Answer2Label")}
        </span>
      </div>
      <p className={"question  common-faq-row"}>
        <span className={"lead-letter"}>
          {getContent("QuestionFirstLetter")}:{" "}
        </span>
        <span className={"faq-text"}>{getContent("Question3Label")}</span>
      </p>
      <div className={"answer common-faq-row"}>
        <span className={"lead-letter"}>
          {getContent("AnswerFirstLetter")}:{" "}
        </span>
        <span className={"faq-text"}>
          {getFormattedContent("Answer3Label")}
        </span>
      </div>
      <p className={"question  common-faq-row"}>
        <span className={"lead-letter"}>
          {getContent("QuestionFirstLetter")}:{" "}
        </span>
        <span className={"faq-text"}>{getContent("Question4Label")}</span>
      </p>
      <div className={"answer common-faq-row"}>
        <span className={"lead-letter"}>
          {getContent("AnswerFirstLetter")}:{" "}
        </span>
        <span className={"faq-text"}>
          {getFormattedContent("Answer4Label")}
        </span>
      </div>
      <p className={"question  common-faq-row"}>
        <span className={"lead-letter"}>
          {getContent("QuestionFirstLetter")}:{" "}
        </span>
        <span className={"faq-text"}>{getContent("Question5Label")}</span>
      </p>
      <div className={"answer common-faq-row"}>
        <span className={"lead-letter"}>
          {getContent("AnswerFirstLetter")}:{" "}
        </span>
        <span className={"faq-text"}>
          {getFormattedContent("Answer5Label")}
        </span>
      </div>
      <p className={"question  common-faq-row"}>
        <span className={"lead-letter"}>
          {getContent("QuestionFirstLetter")}:{" "}
        </span>
        <span className={"faq-text"}>{getContent("Question6Label")}</span>
      </p>
      <div className={"answer common-faq-row"}>
        <span className={"lead-letter"}>
          {getContent("AnswerFirstLetter")}:{" "}
        </span>
        <span className={"faq-text"}>
          {getFormattedContent("Answer6Label")}
        </span>
      </div>
    </div>
  );
}

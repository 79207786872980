import {TableColumn} from "../../models/TableColumn";
import {Table} from "antd";
import React from "react";
import {useRecoilState} from "recoil";

import {RRC_Filters} from "../../components/modelE/Filters";
import {useGlobalState} from "../../state/GlobalState";
import {LastEdited} from "./LastEdited";
import {
  ConfigurationsPerRRC,
  replenishmentAgeConfigurationSelections,
} from "./ReplenishmentAge";
import styles from "../../styles/OrderGrid.module.scss";

export function RRCConfigurationGrid(props: {
  replenishmentAgeGridData: ConfigurationsPerRRC[];
  newAge: number;
  lastEditedBy: string;
  lastEditedDateTime: string;
}) {
  const [, setSelectedConfigurations] = useRecoilState(
    replenishmentAgeConfigurationSelections
  );

  let rowSelection = {
    onChange: (
      selectedRowKeys: React.Key[],
      selectedRows: ConfigurationsPerRRC[]
    ) => {
      const newSelections: ConfigurationsPerRRC[] = selectedRows.map((it) => {
        return {
          key: it.key,
          age: Number(it.age),
          rrc: it.rrc,
          vehicleLine: it.vehicleLine,
          body: it.body,
          paint: it.paint,
          trim: it.trim,
          options: it.options,
        };
      });
      setSelectedConfigurations(newSelections);
    },
  };

  const [selectedFilterDropDownOptions] = useGlobalState(
    "filterDropDownOptions"
  );

  function applyFilter(
    gridContents: ConfigurationsPerRRC[],
    field: string,
    filterValue: string | null | undefined
  ) {
    if (!filterValue) {
      return gridContents;
    }
    if (field === "body") {
      return gridContents.filter(
        (configuration) => configuration.body.description === filterValue
      );
    }
    if (field === "paint") {
      return gridContents.filter(
        (configuration) => configuration.paint.description === filterValue
      );
    }
    if (field === "trim") {
      return gridContents.filter(
        (configuration) => configuration.trim.description === filterValue
      );
    }
    if (field === "vehicleLine") {
      return gridContents.filter(
        (configuration) => configuration.vehicleLine.slice(5) === filterValue
      );
    }
    if (field === "modelYear") {
      return gridContents.filter(
        (configuration) => configuration.vehicleLine.slice(0, 4) === filterValue
      );
    }
    if (field === "age") {
      return gridContents.filter(
        (configuration) =>
          configuration[field as keyof ConfigurationsPerRRC] ===
          Number(filterValue)
      );
    }
    return gridContents.filter(
      (configuration) =>
        configuration[field as keyof ConfigurationsPerRRC] === filterValue
    );
  }

  function getFilteredRRCConfigurationGridData() {
    let filteredGridContents = props.replenishmentAgeGridData;

    RRC_Filters.forEach((filter) => {
      filteredGridContents = applyFilter(
        filteredGridContents,
        filter,
        selectedFilterDropDownOptions.get(filter)?.value
      );
    });

    return filteredGridContents;
  }

  function buildGridColumns2() {
    return [
      new TableColumn({
        title: "Age (days)",
        key: "age(days)",
        render: (data: ConfigurationsPerRRC) => {
          return data.age.toLocaleString();
        },
      }),
      new TableColumn({
        title: "RRC",
        key: "rRC",
        render: (data: ConfigurationsPerRRC) => {
          return data.rrc;
        },
      }),
      new TableColumn({
        title: "Vehicle Line",
        key: "vehicleLine",
        render: (data: ConfigurationsPerRRC) => {
          const [, ...vehicleLineParts] = data.vehicleLine.split(" ");
          return vehicleLineParts.join(" ");
        },
      }),
      new TableColumn({
        title: "Model Year",
        key: "modelYear",
        render: (data: ConfigurationsPerRRC) => {
          return data.vehicleLine.split(" ")[0];
        },
      }),
      new TableColumn({
        title: "Body",
        key: "body",
        render: (data: ConfigurationsPerRRC) => {
          return data.body.description;
        },
      }),
      new TableColumn({
        title: "Paint",
        key: "paint",
        render: (data: ConfigurationsPerRRC) => {
          return data.paint.description;
        },
      }),
      new TableColumn({
        title: "Trim",
        key: "trim",
        render: (data: ConfigurationsPerRRC) => {
          return data.trim.description;
        },
      }),
      new TableColumn({
        title: "Options",
        key: "options",
        render: (data: ConfigurationsPerRRC) => {
          let options = "";
          data.options.forEach((feature) => {
            if (options.length === 0) {
              options = options.concat(feature.description);
            } else {
              options = options.concat(", " + feature.description);
            }
          });
          return options;
        },
      }),
    ];
  }

  return (
    <>
      <div data-testid="order-grid" className={styles.orderGrid}>
        <Table
          className="fmc-table"
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
          columns={buildGridColumns2()}
          dataSource={getFilteredRRCConfigurationGridData()}
          pagination={false}
        />
        <LastEdited
          lastEditedBy={props.lastEditedBy}
          lastEditedDateTime={props.lastEditedDateTime}
        />
      </div>
    </>
  );
}

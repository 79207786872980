import {Route, Routes} from "react-router-dom";
import {Home} from "../pages/home/Home";
import {Login} from "../pages/login/Login";
import {OauthCallback} from "../components/login/OauthCallback";
import {OrderHistory} from "../pages/orderHIstory/OrderHistory";
import {SupplementalVehicleRequests} from "../pages/supplementalVehicleRequests/SupplementalVehicleRequests";
import {FAQ} from "../pages/faq/FAQ";
import {AdminPage} from "../pages/admin/AdminPage";
import React from "react";

export function PeriodicOfferingRoutes() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/oauth-callback" element={<OauthCallback />} />
        <Route path="/history" element={<OrderHistory />} />
        <Route
          path="/supplemental-vehicle-request"
          element={<SupplementalVehicleRequests />}
        />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/admin" element={<AdminPage />} />
        <Route
          path="*"
          element={<h1 className={"page-header"}>PAGE NOT FOUND</h1>}
        />
      </Routes>
    </>
  );
}

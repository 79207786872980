import Select, {SingleValue} from "react-select";
import {useEffect, useState} from "react";
import {DropdownOption} from "../../types/filter-option";
import {ConfigurationGridData} from "../../types/ConfigurationGridData";
import {ConfigurationsPerRRC} from "../../pages/replenishmentAge/ReplenishmentAge";
import {Optionality} from "../../enums/Optionality";

export function DropDownFilter(props: {
  dataGridColumnId: string;
  configurationData?: ConfigurationGridData[];
  rrcConfigurationData?: ConfigurationsPerRRC[];
  onSelection: (
    filterId: string,
    selection: DropdownOption | undefined
  ) => void;
  selectedFilters: Map<string, DropdownOption>;
}) {
  const [dropDownOptions, setDropDownOptions] = useState(
    [] as DropdownOption[]
  );

  function extractDescriptionFromConfigurations(
    configuration: ConfigurationsPerRRC | ConfigurationGridData,
    modelYear: string,
    vehicleLine: string
  ) {
    if (typeof configuration === "object" && "age" in configuration) {
      if (
        props.dataGridColumnId === "body" ||
        props.dataGridColumnId === "paint" ||
        props.dataGridColumnId === "trim"
      ) {
        return configuration[props.dataGridColumnId].description;
      }
      if (props.dataGridColumnId === "modelYear") {
        return modelYear;
      }
      if (props.dataGridColumnId === "vehicleLine") {
        return vehicleLine;
      }
      if (props.dataGridColumnId === "rrc") {
        return configuration[props.dataGridColumnId]
          ? configuration[props.dataGridColumnId]
          : "";
      }
      if (props.dataGridColumnId === "age") {
        return configuration[props.dataGridColumnId]
          ? configuration[props.dataGridColumnId]
          : "0";
      }
    } else if (
      props.dataGridColumnId === "options" ||
      props.dataGridColumnId === "packages"
    ) {
      return configuration[props.dataGridColumnId].flatMap((desc) =>
        desc.optionality === Optionality.OPTIONAL ? desc.description : ""
      );
    }
    // @ts-ignore
    return configuration[props.dataGridColumnId].description;
  }

  useEffect(() => {
    const dataToUse =
      props.rrcConfigurationData || props.configurationData || [];
    const currentFilterOptions = dataToUse
      .map((configuration) => {
        let [modelYear, ...vehicleLineParts] =
          configuration.vehicleLine.split(" ");
        let vehicleLine = vehicleLineParts.join(" ");
        return extractDescriptionFromConfigurations(
          configuration,
          modelYear,
          vehicleLine
        );
      })
      .flat()
      .filter((subArray) => subArray !== "")
      .filter((item, index, arr) => arr.indexOf(item) === index);

    setDropDownOptions(convertStringsToDropdownOptions(currentFilterOptions));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.configurationData, props.rrcConfigurationData]);

  function convertStringsToDropdownOptions(dropdownOption: string[]) {
    return dropdownOption?.map((element: string): DropdownOption => {
      return {
        value: element,
        label: element,
      };
    });
  }

  function updateDropDownOptions(selection: SingleValue<DropdownOption>) {
    props.onSelection(
      props.dataGridColumnId,
      selection ? selection : undefined
    );
  }

  function getCurrentFilter(): DropdownOption | undefined {
    return props.selectedFilters.get(props.dataGridColumnId);
  }

  const isDataAvailable =
    (props.rrcConfigurationData?.length ?? 0) > 0 ||
    (props.configurationData?.length ?? 0) > 0;

  function getFilterPlaceholder() {
    const gridColumnIdMapping: {[index: string]: string} = {
      engine: "BATTERY",
      vehicleLine: "VEHICLE LINE",
      modelYear: "MODEL YEAR",
      age: "AGE (DAYS)",
    };
    const containsFilterGridId = Object.keys(gridColumnIdMapping).includes(
      props.dataGridColumnId
    );
    return containsFilterGridId
      ? gridColumnIdMapping[props.dataGridColumnId]
      : props.dataGridColumnId.toUpperCase();
  }

  return (
    <div>
      <form data-testid={`filterDropdown-${props.dataGridColumnId}`}>
        <label
          className="hidden"
          htmlFor={`filterInput-${props.dataGridColumnId}`}>
          {props.dataGridColumnId.charAt(0).toUpperCase() +
            props.dataGridColumnId.slice(1)}
        </label>
        <Select
          className="sales-code-dropdown"
          options={dropDownOptions}
          name="filterDropDown"
          inputId={`filterInput-${props.dataGridColumnId}`}
          id={`filterDropDown-${props.dataGridColumnId}`}
          placeholder={getFilterPlaceholder()}
          value={getCurrentFilter()}
          onChange={updateDropDownOptions}
          isDisabled={!isDataAvailable}
        />
      </form>
    </div>
  );
}

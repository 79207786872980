import {getGlobalState, useGlobalState} from "../../state/GlobalState";
import {useEffect} from "react";

export function AcceptancePendingModal(props: {
  isVisible: boolean;
  setVisibility: (visible: boolean) => void;
}) {
  const [pendingChanges] = useGlobalState("dealerSelectionList");
  const saleCode = getGlobalState("salesCode");
  const {setVisibility} = props;

  useEffect(() => {
    if (pendingChanges.length === 0) {
      setVisibility(false);
    }
  }, [pendingChanges, setVisibility]);

  return (
    <div
      className={"fmc-dialog fds-align--center fmc-dialog--opened"}
      role="alertdialog"
      aria-modal="true"
      aria-label="acceptance pending modal"
      style={{zIndex: "26"}}>
      <div className="fmc-dialog__content">
        <button
          onClick={() => props.setVisibility(false)}
          aria-label="close modal"
          className="fmc-dialog__close fds-icon fds-font--ford-icons__clear"
        />
        <section>
          <p className={"modal-margins"}>
            You have{" "}
            <span className={"fds-color__text--error1 fds-weight--bold"}>
              {pendingChanges.length} unapproved changes
            </span>{" "}
            for sales code <strong>{saleCode}</strong>
          </p>
          <p className={"modal-margins"}>Please approve or clear changes</p>
          <button
            className="fmc-button"
            onClick={() => props.setVisibility(false)}>
            Okay
          </button>
        </section>
      </div>
    </div>
  );
}

import {getHttpClient} from "./API";
import {ApiUrls} from "../enums/ApiUrls";

export function getCvwDisclaimerAccepted() {
  return getHttpClient().get(ApiUrls.cvwDisclaimerAccepted);
}

export function putCvwDisclaimer() {
  return getHttpClient().put(ApiUrls.cvwDisclaimerAccepted);
}

export const Filters = [
  "body",
  "code",
  "engine",
  "paint",
  "packages",
  "options",
];
export const RRC_Filters = [
  "age",
  "rrc",
  "vehicleLine",
  "modelYear",
  "body",
  "paint",
  "trim",
  // "packages",
];

import {useEffect, useState} from "react";
import {getWarningMessage} from "./WarningMessageService";
import {useGlobalState} from "../../state/GlobalState";
import {AreYouSureButton} from "../areYouSureButton/AreYouSureButton";
import "../../styles/WarningMessage.scss";
import {getHttpClient} from "../../service/API";
import {ApiUrls} from "../../enums/ApiUrls";
import {getSkin, Skin} from "../../service/SkinService";
import {getIcon, IconName} from "../../service/iconFinderService";
import {getContent} from "../../service/ContentService";

export function WarningMessage() {
  const [warningMessage, setWarningMessage] = useState<string>("");
  const [currentLanguage] = useGlobalState("language");
  const [hasAdminPermissions] = useGlobalState("hasAdminPermissions");

  useEffect(() => {
    getWarningMessage().then((response) => {
      if (response.data) {
        setWarningMessage(response.data.message);
      }
    });
  }, []);

  async function clearWarningMessage(): Promise<void> {
    return getHttpClient()
      .delete(ApiUrls.warningMessage)
      .then((response) => {
        if (response.status === 200) {
          setWarningMessage("");
        } else {
          return Promise.reject(`Unexpected response: ${response.status}`);
        }
      });
  }

  return (
    <>
      <div className={"warning-message-container"}>
        <div
          className={
            getSkin() === Skin.LPIVW
              ? "lincoln-warning-message"
              : "warning-message-inner-container"
          }>
          <span
            data-testid="warning-message"
            className={"warning-message-text"}>
            {warningMessage}
          </span>

          {hasAdminPermissions && warningMessage && (
            <AreYouSureButton
              confirmationQuestion={getContent(
                "deleteConfirmation",
                currentLanguage
              )}
              confirmAction={clearWarningMessage}
              buttonLabel={
                <img
                  src={getIcon(IconName.TrashCan)}
                  className="trash-can-icon"
                  alt={"trash can icon"}
                  data-testid={"delete-warning-message"}
                />
              }
            />
          )}
        </div>
      </div>
    </>
  );
}

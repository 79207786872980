import React from "react";
import {useRecoilState} from "recoil";
import ContactForm from "../../state/ContactForm";

export function ConfirmSubmissionModal() {
  const [, contactFormVisibility] = useRecoilState(ContactForm);
  return (
    <div
      className="confirmation-modal fmc-dialog fds-align--center fmc-dialog--opened"
      role="dialog"
      aria-modal="true"
      data-testid="confirmationModal"
      aria-label="Confirm submission modal">
      <div className="fmc-dialog__content">
        <button
          onClick={() => {
            contactFormVisibility(false);
          }}
          data-testid={"close"}
          className="fmc-dialog__close fds-icon fds-font--ford-icons__clear"
          id="close-confirm-submission-modal"
        />
        Your request has been submitted.
        <br />
        <br />
        We understand the time-sensitive nature of requests in the portal. Your
        submission will be given full attention, and we will be in touch if
        needed.
        <br />
        <br />
        If you have an additional issue, please submit another request or close
        out of this modal.
      </div>
    </div>
  );
}

import {useGlobalState} from "../../state/GlobalState";
import {getSkin, Skin} from "../../service/SkinService";
import React from "react";
import downloadArrow from "../../images/download-arrow.svg";

export function DownloadModal() {
  // @typescript-eslint/no-unused-vars
  const [downloadModalVisible, setDownloadModalVisible] = useGlobalState(
    "downloadModalVisible"
  );

  const skin = getSkin();

  return (
    <div
      className={
        "fmc-dialog fds-align--center" +
        (downloadModalVisible ? " fmc-dialog--opened" : "")
      }
      role="dialog"
      aria-modal="true"
      aria-label="download message"
      style={{zIndex: "26"}}>
      <div
        className={
          skin === Skin.LPIVW
            ? "lincoln-dialog__content fmc-dialog__content"
            : "fmc-dialog__content"
        }>
        <button
          onClick={() => setDownloadModalVisible(false)}
          aria-label="close download message"
          className="fmc-dialog__close fds-icon fds-font--ford-icons__clear"
        />
        <img
          src={downloadArrow}
          className="fmc-mb-2"
          data-testid="success-modal"
          alt={"download icon"}></img>
        <h3 className="fmc-type--heading5 fmc-mb-3">Download Initiated</h3>
        <p className="fmc-type--body1 fmc-mb-3">
          <i>Please check your browser's 'Downloads' folder</i>
        </p>
      </div>
    </div>
  );
}

import ReactTooltip from "react-tooltip";
import lincolnTooltip from "../../images/lincolnTooltip.svg";
import "../../styles/RebillingTooltip.scss";
import {getContent} from "../../service/ContentService";
import {useGlobalState} from "../../state/GlobalState";

export function LincolnRebillingTooltip() {
  const [currentLanguage] = useGlobalState("language");
  const tooltipMessage = getContent("tooltip", currentLanguage);
  const tooltipHasContent = () =>
    !(tooltipMessage === "" || tooltipMessage === "TOOLTIP");

  return (
    <>
      {tooltipHasContent() && (
        <div className={"tool-tip"} data-testid={"vehicle-option-tooltip"}>
          <ReactTooltip
            role={"rebilling-tooltip"}
            place={"bottom"}
            effect={"solid"}
            multiline={true}
            id={"rebilling-tooltip"}
            class={"react-tooltip"}
          />
          <span
            className={"tool-tip"}
            data-testid={"tool-tip-span"}
            data-tip={tooltipMessage}
            data-for={"rebilling-tooltip"}>
            <img
              className={"tool-tip-img"}
              src={lincolnTooltip}
              alt={tooltipMessage}
            />
          </span>
        </div>
      )}
    </>
  );
}

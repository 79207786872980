import {getContent} from "../../service/ContentService";
import {useGlobalState} from "../../state/GlobalState";

export function FordFAQ() {
  const [currentLanguage] = useGlobalState("language");

  return (
    <div className={"content-width"}>
      <p className={"question  common-faq-row"}>
        <span className={"lead-letter"}>
          {getContent("QuestionFirstLetter", currentLanguage)}:{" "}
        </span>
        <span className={"faq-text"}>
          {getContent("faqFordAcceptPortal", currentLanguage)}
        </span>
      </p>
      <p className={"answer common-faq-row"}>
        <span className={"lead-letter"}>
          {getContent("AnswerFirstLetter", currentLanguage)}:{" "}
        </span>
        <span className={"faq-text"}>
          {getContent("faqFordFIIAuthority", currentLanguage)}
        </span>
      </p>
      <p className={"question  common-faq-row"}>
        <span className={"lead-letter"}>
          {getContent("QuestionFirstLetter", currentLanguage)}:{" "}
        </span>
        <span className={"faq-text"}>
          {getContent("faqFordNoVehicleOfferings", currentLanguage)}
        </span>
      </p>
      <p className={"answer common-faq-row"}>
        <span className={"lead-letter"}>
          {getContent("AnswerFirstLetter", currentLanguage)}:{" "}
        </span>
        <span className={"faq-text"}>
          {getContent("faqFordDealershipEarned", currentLanguage)}
        </span>
      </p>
      <p className={"question  common-faq-row"}>
        <span className={"lead-letter"}>
          {getContent("QuestionFirstLetter", currentLanguage)}:{" "}
        </span>
        <span className={"faq-text"}>
          {getContent("faqFordAcceptedBeRebilled", currentLanguage)}
        </span>
      </p>
      <p className={"answer common-faq-row"}>
        <span className={"lead-letter"}>
          {getContent("AnswerFirstLetter", currentLanguage)}:{" "}
        </span>
        <span className={"faq-text"}>
          {getContent("faqFordAcceptedBeRebilledA", currentLanguage)}
        </span>
      </p>
      <p className={"question  common-faq-row"}>
        <span className={"lead-letter"}>
          {getContent("QuestionFirstLetter", currentLanguage)}:{" "}
        </span>
        <span className={"faq-text"}>
          {getContent("faqFordAmendOrders", currentLanguage)}
        </span>
      </p>
      <p className={"answer common-faq-row"}>
        <span className={"lead-letter"}>
          {getContent("AnswerFirstLetter", currentLanguage)}:{" "}
        </span>
        <span className={"faq-text"}>
          {getContent("faqFordAmendOrdersA1", currentLanguage)}
          <br />
          <br />
          {getContent("faqFordAmendOrdersA2", currentLanguage)}
        </span>
      </p>
      <p className={"question  common-faq-row"}>
        <span className={"lead-letter"}>
          {getContent("QuestionFirstLetter", currentLanguage)}:{" "}
        </span>
        <span className={"faq-text"}>
          {getContent("faqFordITTroubles", currentLanguage)}
        </span>
      </p>
      <p className={"answer common-faq-row"}>
        <span className={"lead-letter"}>
          {getContent("AnswerFirstLetter", currentLanguage)}:{" "}
        </span>
        <span className={"faq-text"}>
          {getContent("faqFordITTroublesA", currentLanguage)}
        </span>
      </p>
      <p className={"question  common-faq-row"}>
        <span className={"lead-letter"}>
          {getContent("QuestionFirstLetter", currentLanguage)}:{" "}
        </span>
        <span className={"faq-text"}>
          {getContent("faqFordFindAccepted", currentLanguage)}
        </span>
      </p>
      <p className={"answer common-faq-row"}>
        <span className={"lead-letter"}>
          {getContent("AnswerFirstLetter", currentLanguage)}:{" "}
        </span>
        <span className={"faq-text"}>
          {getContent("faqFordFindAcceptedA1", currentLanguage)}
          <br />
          <br />
          {getContent("faqFordFindAcceptedA2", currentLanguage)}
        </span>
      </p>
    </div>
  );
}

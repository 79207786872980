import React from "react";
import {OrderFeature} from "../../types/order";
import {useGlobalState} from "../../state/GlobalState";
import {getContent, searchDescription} from "../../service/ContentService";
import styles from "../../styles/OrderGrid.module.scss";

export interface ExpandedOrderDetails {
  options: OrderFeature[];
  packages: OrderFeature[];
  msrp?: number;
}

interface ExpandedRowProps {
  order: ExpandedOrderDetails;
}

export const expandIcon = (
  <span
    title="expand configuration details"
    className="fds-icon fds-font--ford-icons__chevron-right"></span>
);
export const collapseIcon = (
  <span
    title="collapse configuration details"
    className="fds-icon fds-font--ford-icons__chevron-down"></span>
);

export function OrderGridExpandedRowDetails(props: ExpandedRowProps) {
  const [currentLanguage] = useGlobalState("language");
  const [currentCountry] = useGlobalState("country");
  const [wersDescriptions] = useGlobalState("wersDescriptions");
  const formatOptions = (
    feature: OrderFeature,
    index: number
  ): React.JSX.Element | null => {
    if (
      feature.optionality === "OPTIONAL" ||
      feature.optionality === "SELECTED"
    ) {
      return (
        <li key={index}>
          {searchDescription(
            currentLanguage + "_" + currentCountry,
            feature,
            wersDescriptions
          )}
        </li>
      );
    } else {
      return null;
    }
  };

  const formatPackages = (
    feature: OrderFeature,
    index: number
  ): React.JSX.Element | null => {
    if (
      feature.optionality === "OPTIONAL" ||
      feature.optionality === "SELECTED"
    ) {
      return (
        <li key={index}>
          {searchDescription(
            currentLanguage + "_" + currentCountry,
            feature,
            wersDescriptions
          )}
        </li>
      );
    } else {
      return null;
    }
  };

  return (
    <div className={styles.orderDetails}>
      <div className={styles.packagesSpacing}>
        <span className={`fcm-type--heading3 ${styles.strong}`}>
          {getContent("optionalPackagesLabel", currentLanguage)}
        </span>
        <ul title={"Included Packages"}>
          {props.order.packages.map(formatPackages)}
        </ul>
      </div>
      <div>
        <span className={`fcm-type--heading3 ${styles.strong}`}>
          {getContent("optionalFeaturesLabel", currentLanguage)}
        </span>
        <ul title={"Included Features"}>
          {props.order.options.map(formatOptions)}
        </ul>
      </div>
    </div>
  );
}

import React from "react";
import Select, {SingleValue} from "react-select";

type dropdownProps = {
  limit: number;
  onChange: (configurationId: string, selectedAmount?: number) => void;
  configurationId: string;
  rowKey: string;
  disabled?: boolean;
  selectedAmount?: number;
};

export function AmountRequestedDropDown(props: dropdownProps) {
  function createRangeFromLimit(limit: number) {
    let selectionOptions: SelectionType[] = [];
    for (let i = 0; i <= limit; i++) {
      selectionOptions = [...selectionOptions, {label: i.toString(), value: i}];
    }
    return selectionOptions;
  }

  const selectionOptions = createRangeFromLimit(props.limit);

  interface SelectionType {
    value: number;
    label: string;
  }

  const handleChange = (amount?: SingleValue<SelectionType>) => {
    props.onChange(props.rowKey, amount?.value);
  };

  return (
    <div>
      <form data-testid={`amountRequestedID-${props.rowKey}`}>
        <label className="hidden" htmlFor={"selectionOptions"}>
          Amount Requested
        </label>
        <Select
          value={
            selectionOptions[props.selectedAmount ? props.selectedAmount : 0]
          }
          isDisabled={props.disabled}
          options={selectionOptions}
          aria-label={"Selection options"}
          inputId={"selectionOptions"}
          onChange={handleChange}
        />
      </form>
    </div>
  );
}

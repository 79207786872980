function isInNonProductionEnvironment() {
  return (
    window.location.href.includes("localhost") ||
    window.location.href.includes("preprod")
  );
}

export function Login() {
  let client_id =
    "urn:rrdealerportal:clientid:web_rapidreplenishmentdealerportal:prod";
  let resourceUri =
    "urn:rrdealerportal:resource:web_rapidreplenishmentdealerportal:prod";
  let issuer_environment = "corp";
  if (isInNonProductionEnvironment()) {
    client_id =
      "urn:rrdealerportal:clientid:web_rapidreplenishmentdealerportal:qa";
    resourceUri =
      "urn:rrdealerportal:resource:web_rapidreplenishmentdealerportal:qa";
    issuer_environment = "corpqa";
  }

  const authorizeUrl =
    `https://${issuer_environment}.sts.ford.com/adfs/oauth2/authorize` +
    `?client_id=${client_id}` +
    `&resource=${resourceUri}` +
    "&response_type=" +
    encodeURIComponent("id_token token") +
    "&redirect_uri=" +
    window.location.origin +
    "/oauth-callback";

  window.location.assign(authorizeUrl);
  return null;
}

export function logout() {
  let issuer_environment = "www";
  if (isInNonProductionEnvironment()) {
    issuer_environment = "wwwqa";
  }
  sessionStorage.clear();
  let logoutUrl = `https://${issuer_environment}.faust.idp.ford.com/Federation/SAMLSSO/logout`;
  window.location.assign(logoutUrl);
  return null;
}

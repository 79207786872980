import {parseOAuthCallbackUrl} from "../../service/oauth-parser";
import {SessionStorageKeys} from "../../enums/SessionStorageKeys";

export function OauthCallback() {
  let redirectLink = getRedirectUrl();
  if (window.location.href.search("#") !== -1) {
    parseOAuthCallbackUrl(window.location.href);
    window.location.assign(redirectLink);
  }
  return null;
}

function getRedirectUrl(): string {
  const sessionStorageRedirect = sessionStorage.getItem(
    SessionStorageKeys.OAUTH_REDIRECT
  );
  if (sessionStorageRedirect === null) {
    return "/";
  }
  return sessionStorageRedirect;
}

export function setRedirectURL(setRedirectURL: any) {
  sessionStorage.setItem(SessionStorageKeys.OAUTH_REDIRECT, setRedirectURL);
}

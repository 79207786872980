import {getHttpClient} from "./API";

export interface Dealer {
  Dealer: string;
  dealerName: string;
  Region: string;
  Zone: string;
  DealerwithZone: string;
}

export interface LincolnCanadaDealer {
  brand: string;
  dealerName: string;
  dealerWithZone: string;
  hasHadAllocations: boolean;
  region: string;
  salesCode: string;
  zone: string;
}

export interface DealerInventoryMetrics {
  vehicleLine: string;
  count: number;
}

export async function getDealerInformation(salesCode: string) {
  const response = await getHttpClient().get("/api/dealer/" + salesCode);
  return response.data;
}

export const getDealerInventoryMetrics = async (
  salesCode: string
): Promise<DealerInventoryMetrics[]> => {
  const response = await getHttpClient().get(
    `/api/dealerInventoryMetrics/${salesCode}`
  );
  return response.data;
};

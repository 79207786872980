import {getGlobalState} from "../state/GlobalState";
import {SessionStorageKeys} from "../enums/SessionStorageKeys";

export function isAuthenticated(): boolean {
  return sessionStorage.getItem(SessionStorageKeys.FIRST_NAME) !== null;
}

export function isOnLoginOrOAuthCallbackPage() {
  return (
    window.location.href.search("/oauth-callback") !== -1 ||
    window.location.href.search("/login") !== -1
  );
}

export function getEncodedAccessToken() {
  return sessionStorage.getItem("encodedAccessToken");
}

export function setEncodedAccessToken(accessToken: string) {
  sessionStorage.setItem("encodedAccessToken", accessToken);
}

export function getSalesCode() {
  return getGlobalState("salesCode");
}

export function invalidateLogin() {
  sessionStorage.clear();
  window.location.reload();
}

export function getFirstName() {
  return sessionStorage.getItem(SessionStorageKeys.FIRST_NAME);
}

export function setFirstName(firstName: string) {
  sessionStorage.setItem(SessionStorageKeys.FIRST_NAME, firstName);
}

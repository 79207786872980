import {useGlobalState} from "../../state/GlobalState";
import {getSkin, Skin} from "../../service/SkinService";
import {getContent} from "../../service/ContentService";

export function ErrorModal() {
  // @typescript-eslint/no-unused-vars
  const [errorModalVisible, setErrorModalVisible] =
    useGlobalState("errorModalVisible");
  const [currentLanguage] = useGlobalState("language");

  const skin = getSkin();

  return (
    //TODO Try to refactor ErrorModal into a generic modal for submit button and http errors
    //Code is almost identical, buttons are different
    <div
      className={
        "fmc-dialog fds-align--center" +
        (errorModalVisible ? " fmc-dialog--opened" : "")
      }
      role="alertdialog"
      aria-modal="true"
      aria-label="error message"
      style={{zIndex: "26"}}>
      <div
        className={
          skin === Skin.LPIVW
            ? "lincoln-dialog__content fmc-dialog__content"
            : "fmc-dialog__content"
        }>
        <button
          onClick={() => setErrorModalVisible(false)}
          aria-label="close error message"
          className="fmc-dialog__close fds-icon fds-font--ford-icons__clear"
        />
        <h3 className="fmc-type--heading5 fmc-mb-3">
          {getContent("attentionLabel", currentLanguage)}
        </h3>
        <p className="fmc-type--body1 fmc-mb-3">
          {getContent("errorModalLabel", currentLanguage)}
        </p>
        <div className="fmc-mt-6">
          <button
            onClick={() => setErrorModalVisible(false)}
            className="fmc-button fmc-mb-3"
            aria-label="ok">
            Ok
          </button>
        </div>
      </div>
    </div>
  );
}

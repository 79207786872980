import React, {useEffect, useState} from "react";
import {useGlobalState} from "../../state/GlobalState";
import {DealerSelection} from "../../models/DealerSelection";
import "../../styles/main.scss";
import {DisplayableSelectionStatus} from "../../enums/DisplayableSelectionStatus";
import {BackendSelectionStatus, FormattedOrder} from "../../types/order";
import {getContent} from "../../service/ContentService";

interface VehicleDataProps {
  order: FormattedOrder;
}

export function AcceptReject(props: VehicleDataProps) {
  const [currentLanguage] = useGlobalState("language");
  const [offeringWindowOngoing] = useGlobalState("offeringWindowOngoing");
  const [reallocationPeriodOngoing] = useGlobalState(
    "reallocationPeriodOngoing"
  );
  const [unsavedDealerSelections, setDealerSelectionList] = useGlobalState(
    "dealerSelectionList"
  );

  const selectedButtonStyle = "fmc-button accept-reject-button";
  const notSelectedButtonStyle =
    "fmc-button accept-reject-button fmc-button--outlined";
  const [acceptStyle, setAcceptStyle] = useState(notSelectedButtonStyle);
  const [rejectStyle, setRejectStyle] = useState(notSelectedButtonStyle);

  useEffect(() => {
    let dealerSelection = getDealerSelection();
    if (dealerSelection) setDealerChoice(dealerSelection.response);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function getDealerSelection() {
    let dealerSelection = unsavedDealerSelections.find(
      (selection: DealerSelection) => {
        return selection.vin === props.order.vin;
      }
    );

    if (
      dealerSelection === undefined &&
      props.order.formattedSelection !== undefined
    ) {
      dealerSelection = {
        response: props.order.formattedSelection,
        vin: props.order.vin,
      };
    }

    return dealerSelection;
  }

  function addOrUpdateDealerSelectionList(dealerSelection: DealerSelection) {
    let containsDuplicate = false;
    let updatedDealerSelectionList: any = unsavedDealerSelections.map(
      (selection) => {
        if (selection.vin === props.order.vin) {
          containsDuplicate = true;
          return dealerSelection;
        }
        return selection;
      }
    );

    if (!containsDuplicate) {
      updatedDealerSelectionList = [...unsavedDealerSelections];
      updatedDealerSelectionList.push(dealerSelection);
    }
    setDealerSelectionList(updatedDealerSelectionList);
  }

  function setDealerChoice(choice: string) {
    if (choice === DisplayableSelectionStatus.Accepted) {
      setAcceptStyle(selectedButtonStyle);
      setRejectStyle(notSelectedButtonStyle);
    } else if (choice === DisplayableSelectionStatus.Rejected) {
      setAcceptStyle(notSelectedButtonStyle);
      setRejectStyle(selectedButtonStyle);
    } else {
      setAcceptStyle(notSelectedButtonStyle);
      setRejectStyle(notSelectedButtonStyle);
    }
  }

  function acceptOnClick() {
    setDealerChoice(DisplayableSelectionStatus.Accepted);

    const dealerSelection: DealerSelection = {
      response: DisplayableSelectionStatus.Accepted,
      vin: props.order.vin,
    };
    addOrUpdateDealerSelectionList(dealerSelection);
  }

  function rejectOnClick() {
    setDealerChoice(DisplayableSelectionStatus.Rejected);

    const dealerSelection: DealerSelection = {
      response: DisplayableSelectionStatus.Rejected,
      vin: props.order.vin,
    };
    addOrUpdateDealerSelectionList(dealerSelection);
  }

  if (
    offeringWindowOngoing ||
    (reallocationPeriodOngoing &&
      props.order.selection === BackendSelectionStatus.Reallocated)
  ) {
    return (
      <div data-testid="accept-reject" className={"accept-reject"}>
        <button
          className={acceptStyle}
          id={"accept-button"}
          data-testid={"accept-button"}
          value={DisplayableSelectionStatus.Accepted}
          onClick={acceptOnClick}>
          {getContent("acceptLabel", currentLanguage)}
        </button>
        <button
          className={rejectStyle}
          id={"reject-button"}
          data-testid={"reject-button"}
          value={DisplayableSelectionStatus.Rejected}
          onClick={rejectOnClick}>
          {getContent("rejectLabel", currentLanguage)}
        </button>
      </div>
    );
  } else {
    return <div />;
  }
}

import {ExportRebillingReport} from "../reports/ExportRebillingReport";
import {ExportFullReport} from "../reports/ExportFullReport";
import React from "react";

export function AdminTools() {
  return (
    <div className="admin-tools">
      <ExportFullReport />
      <ExportRebillingReport />
    </div>
  );
}

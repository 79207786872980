import {SalesCodeSelector} from "../orderGridFilters/SalesCodeSelector";
import {useEffect, useState} from "react";
import {
  getStockingGuide,
  StockingGuide,
  updateStockingGuide,
} from "../../service/StockingGuideService";
import styles from "../../styles/AdminStockingGuide.module.scss";

export function AdminStockingGuide() {
  const [salesCode, setSalesCode] = useState("");
  const [stockingGuide, setStockingGuide] = useState<StockingGuide | null>(
    null
  );
  const [currentStockTarget, setCurrentStockTarget] = useState("");
  const [successStatus, setSuccessStatus] = useState(false);

  useEffect(() => {
    if (salesCode) {
      getStockingGuide(salesCode, "F-150").then((response) => {
        setStockingGuide(response);
        setCurrentStockTarget(response.stockTarget.toString());
      });
    }
  }, [salesCode]);

  function handleSalesCodeChange(event: any) {
    setSalesCode(event.value);
    setSuccessStatus(false);
  }

  function stockTargetChange(event: React.ChangeEvent<HTMLInputElement>) {
    setCurrentStockTarget(event.target.value);
  }

  function onSubmit() {
    if (stockingGuide) {
      updateStockingGuide(
        stockingGuide.salesCode,
        stockingGuide.vehicleLine,
        parseInt(currentStockTarget),
        stockingGuide.demoAllowance
      ).then(() => setSuccessStatus(true));
    }
  }

  return (
    <div className={styles.adminStockingGuide}>
      <SalesCodeSelector
        salesCode={salesCode}
        salesCodeChangeEventHandler={handleSalesCodeChange}
      />

      {successStatus && <p>Stocking Guide successfully updated</p>}

      {stockingGuide && (
        <>
          <p>
            <label htmlFor="stockTarget">Stock Target</label>
            <input
              type={"text"}
              id={"stockTarget"}
              onChange={stockTargetChange}
              value={currentStockTarget}></input>
          </p>
          <button className={"fmc-button"} onClick={onSubmit}>
            Update Stocking Guide
          </button>
        </>
      )}
    </div>
  );
}

import {getHttpClient} from "./API";
import {ApiUrls} from "../enums/ApiUrls";
import {Brand, getBrand} from "./BrandService";
import {getSkin} from "./SkinService";

export interface SalesCodeType {
  value: string;
  label: string;
}

function createSalesCodePair(item: string): SalesCodeType {
  return {value: item, label: item};
}

export async function getSalesCodesByBrand(
  brand: Brand
): Promise<SalesCodeType[]> {
  let url = `/api/salesCodes?brand=${brand.toUpperCase()}`;
  let httpClient = getHttpClient();

  return httpClient
    .get<string[]>(url)
    .then((response) => {
      return response.data.map((item: string) => createSalesCodePair(item));
    })
    .catch((error) => {
      console.log(error.response.status);
      return [];
    });
}

async function getSalesCodesFrom(path: string): Promise<SalesCodeType[]> {
  let httpClient = getHttpClient();
  const response = await httpClient.get(path);

  return response.data?.map((item: string) => createSalesCodePair(item));
}

export async function retrieveAllAvailableSalesCodesForAuthenticatedUser(): Promise<
  SalesCodeType[]
> {
  return await getSalesCodesByBrand(getBrand(getSkin()));
}

export async function retrieveReallocatableSalesCodes(
  salesCode: string
): Promise<SalesCodeType[]> {
  return getSalesCodesFrom(
    ApiUrls.getReallocateSalesCodes + "?reallocatableWithSalesCode=" + salesCode
  );
}

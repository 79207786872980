import {getHttpClient} from "./API";
import {ApiUrls} from "../enums/ApiUrls";
import {
  ConfigurationsPerRRC,
  EnumeratedRrcVehicleConfiguration,
} from "../pages/replenishmentAge/ReplenishmentAge";

export async function getCurrentRrcConfigurations(): Promise<
  EnumeratedRrcVehicleConfiguration[]
> {
  let requestUrl: string = ApiUrls.loadRrcConfigurations;
  const response = await getHttpClient().get(requestUrl);
  console.log("response.data: " + response.data);
  return response.data;
}

export async function submitNewAge(
  data: ConfigurationsPerRRC[],
  newAge: string
) {
  let convertedData = data.map((it) => {
    return {
      _id: it.key,
      age: newAge,
      rrc: it.rrc,
      vehicleLine: it.vehicleLine,
      body: it.body,
      paint: it.paint,
      trim: it.trim,
      packages: it.options,
    };
  });
  let requestUrl: string = ApiUrls.updateRrcConfigurations;
  await getHttpClient().put(requestUrl, convertedData);
}

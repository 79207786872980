import {useNavigate} from "react-router-dom";
import {ListItemIcon, ListItemText, MenuItem} from "@mui/material";
import * as React from "react";
import {getIcon, IconName} from "../../../service/iconFinderService";
import {getContent} from "../../../service/ContentService";
import {useGlobalState} from "../../../state/GlobalState";

export function FAQMenuButton() {
  const [currentLanguage] = useGlobalState("language");
  const navigation = useNavigate();
  return (
    <>
      <MenuItem
        data-testid={"faq-menu-button"}
        onClick={() => {
          navigation("/faq");
        }}>
        <ListItemIcon>
          <img
            src={getIcon(IconName.FAQ)}
            className="menu-button-icons"
            alt={"faq menu button"}
          />
        </ListItemIcon>
        <ListItemText>
          {getContent("dealerFAQMenuLabel", currentLanguage)}
        </ListItemText>
      </MenuItem>
    </>
  );
}

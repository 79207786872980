import Select from "react-select";
import {getContent} from "../../service/ContentService";
import {useEffect, useState} from "react";
import {
  getCompletedAndActiveOfferings,
  Offering,
} from "../../service/OfferingService";
import {AdminPageReports} from "../../components/admin/AdminPageReports";
import {useGlobalState} from "../../state/GlobalState";
import {getSkin, Skin} from "../../service/SkinService";
import {DownloadModal} from "../../components/downloadModal/DownloadModal";
import {PermissionsAdminTool} from "../../components/admin/PermissionsAdminTool";
import {getHttpClient} from "../../service/API";
import {AdminStockingGuide} from "../../components/admin/AdminStockingGuide";
import styles from "../../styles/AdminPage.module.scss";

export function AdminPage() {
  const [offerings, setOfferings] = useState([]);
  const [selectedOffering, setSelectedOffering] = useState({
    id: "",
    dateRange: "",
  });
  const [stillAvailableVehicles, setStillAvailableVehicles] = useState<
    {vin: String; salesCode: String}[]
  >([]);

  const [currentLanguage] = useGlobalState("language");

  const [isAdmin] = useGlobalState("hasAdminPermissions");
  useEffect(() => {
    getCompletedAndActiveOfferings().then((offeringData) => {
      setOfferings(
        offeringData.map((offerings: Offering) => {
          return {label: offerings.dateRange, value: offerings._id};
        })
      );
    });
  }, []);

  /**
   * Can delete after Rebilling automation is complete
   */
  function getOnHoldVehiclesCall() {
    getHttpClient()
      .get(`/api/modelE/order/onHold`)
      .then((resp) => {
        setStillAvailableVehicles(resp.data.stillAvailable);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <>
      {isAdmin && (
        <>
          <h1
            className={
              getSkin() === Skin.LPIVW
                ? styles.lincolnAdminPageHeader
                : styles.adminPageHeader
            }>
            {getContent("adminDashboard", currentLanguage)}
          </h1>
          <div className={styles.adminDashboardContainer}>
            <div className={styles.adminElementContainers}>
              <PermissionsAdminTool />
            </div>
            <div
              className={styles.adminElementContainers}
              style={{gridColumn: "2 / span 3"}}>
              <h2 className={styles.permissionsHeader}>
                {getContent("adminDownloadReports", currentLanguage)}
              </h2>
              <p className={styles.adminText}>
                {getContent("adminReportDescription", currentLanguage)}
              </p>

              <table className={styles.adminBar}>
                <thead>
                  <tr className={styles.adminBarTop}>
                    <th>{getContent("adminOfferPeriod", currentLanguage)}</th>
                    <th>
                      {getContent("adminDownloadReportRow", currentLanguage)}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Select
                        aria-label={"Offerings"}
                        className={styles.select}
                        placeholder={getContent(
                          "selectOfferingPeriodPlaceholderText",
                          currentLanguage
                        )}
                        options={offerings}
                        onChange={(event: any) =>
                          setSelectedOffering({
                            id: event.value,
                            dateRange: event.label,
                          })
                        }
                      />
                    </td>
                    <td>
                      <AdminPageReports offeringId={selectedOffering.id} />
                    </td>
                  </tr>
                </tbody>
              </table>
              <DownloadModal />
            </div>
            {/*Can delete after Rebilling automation is complete*/}
            {getSkin() === Skin.MEW && (
              <>
                <div className={styles.adminElementContainers}>
                  <button
                    data-testid="on-hold-button"
                    className="fmc-button"
                    onClick={() => getOnHoldVehiclesCall()}>
                    Get ON HOLD vehicles
                  </button>
                  <ul className={styles.onHoldVehicles}>
                    {stillAvailableVehicles &&
                      stillAvailableVehicles.map((vehicle) => (
                        <li key={vehicle.vin.toString()}>
                          VIN: {vehicle["vin"]} - Sales Code:{" "}
                          {vehicle["salesCode"]}
                        </li>
                      ))}
                  </ul>
                </div>
                <div className={styles.adminElementContainers}>
                  <h2 className={styles.permissionsHeader}>
                    Update Stock Target
                  </h2>
                  <AdminStockingGuide />
                </div>
              </>
            )}
          </div>
        </>
      )}
      {!isAdmin && <h1 className={"page-header"}>403 UNAUTHORIZED</h1>}
    </>
  );
}

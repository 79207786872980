import React from "react";

import "../../styles/LastEdit.css";

export function LastEdited(props: {
  lastEditedBy: string;
  lastEditedDateTime: string;
}) {
  function dateFromDateTime(lastEditedDateTime: string) {
    return new Date(lastEditedDateTime).toLocaleString("default", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });
  }

  const lastEditedDate: string = dateFromDateTime(props.lastEditedDateTime);

  function timeFromDateTime(lastEditedDateTime: string) {
    return new Date(lastEditedDateTime).toLocaleString("default", {
      hour: "2-digit",
      minute: "2-digit",
    });
  }

  const lastEditedTime: string = timeFromDateTime(props.lastEditedDateTime);

  function determineLastEditedContent() {
    if (props.lastEditedBy && lastEditedDate && lastEditedTime) {
      return `Last edit by ${props.lastEditedBy.toUpperCase()} on ${lastEditedDate} at ${lastEditedTime}`;
    } else {
      return "Unable to determine most recent edit";
    }
  }

  return (
    <div>
      <p className={"last-edited"}>{determineLastEditedContent()}</p>
    </div>
  );
}

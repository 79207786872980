import {getSkin, Skin} from "./SkinService";
import React from "react";
import {OrderFeature, VehicleLineWersDescriptions} from "../types/order";
import {getAllVehicleLineDescriptions} from "./OrderService";
import {setGlobalState} from "../state/GlobalState";
import {ConfigurationFeature} from "../models/Configuration";

export interface DescriptionTable {
  vehicleLine: String;
  descriptions: LanguageMap[];
}

export interface LanguageMap {
  language: String;
  wersDescriptions: Map<String, String>;
}

function handleMissingContent(content: string | undefined, contentID: string) {
  if (content === undefined || content.length < 1) {
    return contentID.toUpperCase();
  }

  return content;
}

export function getContentFromLibrary(contentID: string, language: string) {
  switch (getSkin()) {
    case Skin.RR:
      return rrContentLibrary[language][contentID];
    case Skin.LPIVW:
      return lpivwContentLibrary[language][contentID];
    case Skin.CVW:
      return cvwContentLibrary[language][contentID];
    case Skin.MEW:
      return mewContentLibrary[language][contentID];
    default:
      return handleMissingContent(undefined, contentID);
  }
}

export function formatAsCurrency(currency: number): string {
  return currency.toLocaleString(new Intl.Locale("en-US"), {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
  });
}

export function getContent(contentID: string, language: string = "en") {
  let content: string = baseContentLibrary[language][contentID];

  if (content === undefined) {
    content = handleMissingContent(
      getContentFromLibrary(contentID, language),
      contentID
    );
  }

  return content;
}

export function getCurrentCountry(salesCode: string) {
  let firstLetter = salesCode.at(0);
  let country = undefined;
  switch (firstLetter) {
    case "C":
      country = "CA";
      break;
    default:
      country = "US";
      break;
  }
  setGlobalState("country", country);
}

export function getAllDescriptionsByVehicleLineAndCountry(
  vehicleLine: string,
  currentCountry: string
) {
  getAllVehicleLineDescriptions(vehicleLine, currentCountry).then(
    (response: {data: VehicleLineWersDescriptions}) => {
      if (response.data && Object.keys(response.data).length !== 0) {
        const responseData = response.data;
        const descriptionsMap = new Map<string, Map<string, string>>();
        if (responseData.descriptions) {
          Object.entries(responseData.descriptions).forEach(([key, value]) => {
            descriptionsMap.set(key, new Map(Object.entries(value as any)));
          });
        }
        setGlobalState("wersDescriptions", {
          ...responseData,
          descriptions: descriptionsMap,
        });
      }
    }
  );
}

export function searchDescription(
  locale: string,
  code: OrderFeature,
  wersDescriptions: VehicleLineWersDescriptions
) {
  if (
    wersDescriptions &&
    wersDescriptions.descriptions &&
    wersDescriptions.descriptions.get(locale.toString()) &&
    wersDescriptions.descriptions.get(locale.toString())?.has(code.wersCode)
  ) {
    return wersDescriptions.descriptions.get(locale)?.get(code.wersCode);
  }

  return code.description;
}

export function searchDescriptionModelE(
  locale: string,
  code: ConfigurationFeature,
  wersDescriptions: VehicleLineWersDescriptions
) {
  if (
    wersDescriptions &&
    wersDescriptions.descriptions &&
    wersDescriptions.descriptions.get(locale.toString()) &&
    wersDescriptions.descriptions.get(locale.toString())?.has(code.wersCode)
  ) {
    return wersDescriptions.descriptions
      .get(locale)
      ?.get(code.wersCode.toString());
  }

  return code.description;
}

export function getFormattedContent(
  contentID: string,
  language: string = "en"
) {
  let content: string = baseContentLibrary[language][contentID];

  if (content === undefined) {
    content = handleMissingContent(
      getContentFromLibrary(contentID, language),
      contentID
    );
  }
  return <div dangerouslySetInnerHTML={{__html: content}}></div>;
}

const baseContentLibrary: {[index: string]: any} = {
  en: {
    orderHistoryMenuButton: "Order History",
    allocationMethodologyLabel: "ALLOCATION METHODOLOGY",
    homeText: "Home",
    orderHistorySalesCodeLabel: "Sales Code",
    orderHistoryRegionLabel: "Region",
    orderHistoryOfferingPeriodLabel: "Offer Period",
    orderHistoryOfferingDownloadLabel: "Download Offer Period Report",
    orderHistoryViewReportLabel: "Preview Offer Period Report",
    orderHistoryDownloadReportLabel: "Export Offer Period Report",
    orderHistoryDownloadButton: "Download",
    salesCodePlaceholderText: "SALES CODE",
    regionPlaceholderText: "REGION",
    offeringPlaceholderText: "OFFER PERIOD",
    selectOfferingPeriodPlaceholderText: "SELECT OFFERING PERIOD",
    selectSalesCodePlaceholderText: "Select a sales code",
    selectVehicleLinePlaceholderText: "Select a vehicle line",
    selectSalesCodeWarningMessage: "Please select a sales code",
    selectVehicleLineWarningMessage: "Please select a vehicle line",
    vehicleOfferingLabel: "VEHICLE OFFERING",
    offeringStatusLabel: "OFFERING STATUS",
    greetingText: "Hi, ",
    vinLabel: "VIN",
    exportFullReportButton: "Export Full Report",
    exportRebillingReportButton: "Export Rebilling Report",
    offerEndsMessage: "Offer ends: ",
    vehiclesLeftToReviewText: "vehicles left to review",
    acceptedLabel: "Accepted",
    rejectedLabel: "Rejected",
    acceptLabel: "Accept",
    rejectLabel: "Reject",
    approveButtonMessage:
      "* Your selections will not be saved unless you click the <b>Approve</b> button. Unsaved changes will be lost.",
    approveButtonLabel: "Approve",
    submitButtonTooltip:
      "Selections need to take place before vehicle submission",
    supplementalVehicleRequestLabel: "Supplemental Vehicle Request",
    supplementalVehicleRequestMenuLabel: "Supplemental <br /> Vehicle Request",
    dealerFAQMenuLabel: "Dealer FAQ",
    contactUsMenuLabel: "Contact Us",
    logoutMenuLabel: "Logout",
    dealershipNameLabel: "Dealership Name",
    bodyLabel: "Body",
    codeLabel: "Code",
    engineLabel: "Engine",
    paintLabel: "Paint",
    trimLabel: "Trim",
    statusLabel: "Status",
    reallocateLabel: "Reallocate",
    submitReallocationLabel: "Submit Reallocation",
    userLabel: "User",
    optionsLabel: "Options",
    packagesLabel: "Packages",
    orderGridDefaultMessage:
      "Please select a Sales Code. If a Sales Code does not appear in the list you do not have permission to submit wholesale orders.",
    downloadCSVLabel: "Download CSV",
    SVRMessage:
      "If given the option to receive more vehicles than selected in the allocation, what vehicles would you like to request and how many?",
    PEPCodeLabel: "PEP Code",
    vehicleLineLabel: "Vehicle Line",
    downloadCSVTooltip:
      "Download the report for Supplemental<br/>Vehicle Requests for the current offering.",
    amountLabel: "Amount",
    svrWarningMessage:
      "Please note that submitting a request does not guarantee the vehicles will be available to be delivered. You will be notified via email of the status of your request.",
    submitButtonLabel: "Submit Request",
    lostSelections:
      "* Your selections will not be saved unless you click the <b>Approve</b> button. Unsaved changes will be lost.",
    FAQTitleLabel: "FREQUENTLY ASKED QUESTIONS (FAQ)",
    QuestionFirstLetter: "Q",
    AnswerFirstLetter: "A",
    contactUsHeader1:
      'Having an issue with the portal? For permissions or access issues, please refer to the <a className={"link-styling"} href="/faq">Dealer FAQ</a> page or contact your Vehicle Sales Operations Lead.',
    contactUsHeader2:
      "Please fill out the form below to help us address your issue quickly. Required fields are marked with a ",
    contactUsCDSIDInput: "Enter WSLX ID or CDSID",
    contactUsCDSIDWarning: "Please provide a WSLX ID or CDSID",
    contactUsSalesCode: "Enter sales code",
    contactUsSalesCodeWarning: "Please provide a sales code",
    contactUsEmail: "Enter your contact email",
    contactUsDescription:
      "Please provide relevant information pertaining to your issue (e.g. the browser, if this was previously working for you, and what steps you may have already taken). Please be descriptive.",
    contactUsDescriptionWarning: "Please provide a description of your issue",
    adminDashboard: "Admin Dashboard",
    adminDownloadReports: "Download Reports",
    adminReportDescription:
      "Download or preview reports of accepted, rejected and allocated vehicles in each offer period.",
    adminOfferPeriod: "Offer Period",
    adminDownloadReportRow: "Download Report",
    permissionLookup: "Permission Lookup",
    permissionCDSPlaceholder: "CDSID",
    warningMessageHeader: "URGENT MESSAGING",
    warningMesssageText:
      "If you have an <strong>urgent message</strong> that needs to be displayed to <strong>all</strong> Portal users the next time they login, enter it below.",
    warningMessagePlaceholder:
      "Please enter the contents of the Urgent Message as it should appear to Portal users.",
    warningMessageButton: "Submit Message",
    warningMessageMenuLabel: "Set Warning Message",
    adminMenuLabel: "Admin",
    confirmationEmailWithDefaultEmailPart1:
      "Your confirmation will be sent to ",
    confirmationEmailWithDefaultEmailPart2:
      " as well as the email address you enter below:",
    confirmationRequestingEmail:
      "Your confirmation will be sent to the email address you enter below:",
    doNotRecognizeEmailLabel:
      "If you don't recognize this email and wish to change it, select ‘Change my Profile’ in your ",
    userProfileLabel: "User Profile",
    emailWillBeUpdatedLabel:
      ". The email will be updated the next time you log in to the portal.",
    enterEmailHereLabel: "enter email here",
    noPermissionForSalesCode:
      "You don't have permission to submit orders for this sales code.",
    offerNotYetStarted: "Offer not yet started",
    offerStarts: "Offer starts:",
    dealerOrderWindowClosed:
      "The dealer order window is closed. No vehicle offerings available at this time.",
    noOptionsSelector: "No options",
    noSVRDataFound: "No data",
    SVRSuccessModalMessage:
      "Your supplemental vehicle was submitted successfully.",
    SVRSubmitNewRequest: "Submit new request",
    SVRGoToHome: "Go to Home",
    NoVehicleForSalesCode:
      "Selected sales code has no allocated vehicles for current offering.",
    ReallocationPeriod:
      "Reallocation Period: The standard offering window has now closed. If a vehicle is reallocated to your dealership during the reallocation period, you will be able to accept or reject that vehicle.",
    ReallocationPeriodLabel: "REALLOCATION PERIOD",
    LimitedActionPeriod: "Limited action period ends:",
    DuringReallocationPeriod:
      "During the reallocation period, any vehicles reallocated to the selected sales code will appear here.",
    SelectVehicleLine: "Please select a vehicle line to see vehicle offerings.",
    NoCurrentOfferings:
      "You have no current offerings for this vehicle line. Please select another vehicle line.",
    EnterSalesCodeLabel: "Enter sales code to receive vehicle:",
    ReallocatingLabel: "Reallocating",
    searchButton: "Search",
    vehiclesMustBeRejected:
      "Vehicles must be rejected before they can be reallocated",
    urgentMessageSuccess: "Your urgent message was submitted successfully.",
    refreshPage: "Please refresh the page to see the changes.",
    closeButtonLabel: "Close",
    deleteConfirmation: "Are you sure you want to delete the warning message?",
    confirmationButton: "Yes, I'm sure",
    cancelButton: "Cancel",
    errorModalLabel: "Sorry, an error occurred. Please try again later.",
    attentionLabel: "ATTENTION",

    noPastOfferings: "No past offerings for selected sales code",
    continueLabel: "Continue",
    optionalPackagesLabel: "Included Packages",
    optionalFeaturesLabel: "Included Features",
    lastModifiedBy: "Last modified by ",
    onLabel: " on ",
    orderHistoryOf: "Order History of ",
    orderHistoryFor: " for ",
    faqFordAcceptPortal:
      "Who can accept portal based allocation at the dealership? How can I gain access?",
    faqFordFIIAuthority:
      "Persons with FII authority at the dealership should be accepting Rapid Replenishment and Configured Vehicle wholesale allocation. Persons must have “Order Management: Rapid_Replenishment” permission to complete the wholesale in the portal. Please contact your administrator for assistance.",
    faqFordNoVehicleOfferings:
      "No vehicle offerings are showing when I enter my sales code. What does this mean?",
    faqFordDealershipEarned:
      "Dealership earned zero (0) units for the offering period for the displayed vehicle line. Be sure to check all vehicle lines using the vehicle line drop down.",
    faqFordAcceptedBeRebilled:
      "When will my accepted units be rebilled to my dealership?",
    faqFordAcceptedBeRebilledA:
      "Accepted units will be rebilled to dealership within ten (10) business days of portal closure. Please check the FMC Dealer Message Center posting for exact dates.",
    faqFordAmendOrders: "Can I amend any items on my accepted orders?",
    faqFordAmendOrdersA1:
      "Rapid Replenishment – Once units have been accepted and rebilled, the orders will become visible in WBDO. At that point, dealers may be able to adjust some features such as paint and dealer installed options before the order amendment window closes.",
    faqFordAmendOrdersA2:
      "Configured Vehicle Wholesale – Once units have been accepted and rebilled, the orders will become visible in WBDO. At that point, dealers will not be able to adjust features.",
    faqFordITTroubles: "I am having IT troubles with the site.",
    faqFordITTroublesA:
      "Please try another browser or clear your cache. Also, only persons with authority to approve Rapid Replenishment wholesale (FII) can access the offering. If further assistance is needed, please utilize the “Contact Us” feature on the portal site via the menu drop down.",
    faqFordFindAccepted:
      "How can I find my accepted Rapid Replenishment units in WBDO?",
    faqFordFindAcceptedA1:
      'Rapid Replenishment - Utilize the “Display” Drop Down on the Orders tab to select "Rapid Replenishment"',
    faqFordFindAcceptedA2:
      "CVW – Filter to Vehicle line, filter to priority code 80",
    dealerSelection: "Dealer Selection",
  },
  fr: {
    orderHistoryMenuButton: "Historique des commandes",
    orderHistoryPageDescription:
      "Téléchargez les rapports des véhicules acceptés, refusés et alloués pour chaque période d'offre.",
    orderHistoryPageSubject: "HISTORIQUE DES COMMANDES",
    orderHistorySalesCodeLabel: "Code de vente",
    orderHistoryRegionLabel: "Région",
    orderHistoryOfferingPeriodLabel: "Période d'offre",
    orderHistoryOfferingDownloadLabel:
      "Télécharger le rapport de la période d'offre",
    orderHistoryViewReportLabel: "Aperçu du rapport de la période d'offre",
    orderHistoryDownloadReportLabel:
      "Exporter le rapport de la période d'offre",
    orderHistoryDownloadButton: "Télécharger",
    salesCodePlaceholderText: "CODE de VENTE",
    regionPlaceholderText: "RÉGION",
    offeringPlaceholderText: "PÉRIODE D'OFFRE",
    selectOfferingPeriodPlaceholderText: "Sélectionner la période de l’offre",
    homeText: "Accueil",
    allocationMethodologyLabel: "MÉTHODOLOGIE D’ALLOCATION",
    selectSalesCodePlaceholderText: "Sélectionner un code de vente",
    selectVehicleLinePlaceholderText: "Sélectionner une gamme de véhicules",
    selectSalesCodeWarningMessage: "Veuillez sélectionner un code de vente",
    selectVehicleLineWarningMessage:
      "Veuillez sélectionner une gamme de véhicules",
    vehicleOfferingLabel: "OFFRE DE VÉHICULES",
    offeringStatusLabel: "ÉTAT DE L’OFFRE",
    greetingText: "Bonjour, ",
    vinLabel: "NIV",
    exportFullReportButton: "Exporter le rapport intégral",
    exportRebillingReportButton: "Exporter le rapport de refacturation",
    offerEndsMessage: "L’offre expire le: ",
    vehiclesLeftToReviewText: "offre(s) de yy à examiner",
    acceptedLabel: "Acceptée",
    rejectedLabel: "Rejetée",
    acceptLabel: "Accepter",
    rejectLabel: "Rejeter",
    approveButtonMessage:
      "* Vos sélections ne seront pas enregistrées à moins que vous ne cliquiez sur le bouton <strong> Approuver </strong>. Les changements non enregistrés seront perdus.",
    approveButtonLabel: "Approuver",
    submitButtonTooltip:
      "Vous devez faire vos sélections avant de soumettre le véhicule",
    supplementalVehicleRequestLabel: "Demandes de véhicules supplémentaires",
    supplementalVehicleRequestMenuLabel:
      "Demandes de <br/> véhicules supplémentaires",
    dealerFAQMenuLabel: "FAQ pour les concessionnaires",
    contactUsMenuLabel: "Nous joindre",
    logoutMenuLabel: "Fermer la session",
    dealershipNameLabel: "Nom du concessionnaire",
    bodyLabel: "Modèle",
    codeLabel: "Code",
    engineLabel: "Moteur",
    paintLabel: "Peinture",
    trimLabel: "Version",
    statusLabel: "État",
    userLabel: "Utilisateur",
    optionsLabel: "Options",
    packagesLabel: "Ensembles",
    reallocateLabel: "Réattribuer",
    submitReallocationLabel: "Soumettre Réattribuer",
    orderGridDefaultMessage:
      "Veuillez sélectionner un code de vente. Si un code de vente ne s’affiche pas dans la liste, vous n’avez pas la permission de soumettre des commandes en gros.",
    downloadCSVLabel: "Télécharger au format CSV",
    SVRMessage:
      "Si vous avez la possibilité de recevoir plus de véhicules que ceux sélectionnés dans le cadre de l’allocation, quels véhicules souhaiteriez-vous demander, et combien d’unités?",
    PEPCodeLabel: "Code de groupe d’équipements préférés",
    vehicleLineLabel: "Gamme de véhicules",
    downloadCSVTooltip:
      "Téléchargez le rapport pour demander <br/>plus de véhicules que l’offre actuelle.",
    amountLabel: "Quantité",
    svrWarningMessage:
      "Veuillez noter que le fait de soumettre une demande ne garantit pas que tous les véhicules demandés pourront vous être livrés. Vous serez informé par courriel de l’état de votre demande.",
    submitButtonLabel: "Soumettre la demande",
    FAQTitleLabel: "FOIRE AUX QUESTIONS (FAQ)",
    QuestionFirstLetter: "Q",
    AnswerFirstLetter: "R",
    contactUsHeader1:
      'Vous avez un problème avec le portail? Pour les problèmes de permissions ou d\'accès, veuillez consulter <a className={"link-styling"} href="/faq">la FAQ_pour les concessionnaires</a> ou votre Responsable des opérations, ventes de véhicules.',
    contactUsHeader2:
      "Veuillez remplir le formulaire ci-dessous pour nous aider à régler votre problème rapidement. Les champs obligatoires sont marqués du symbole ",
    contactUsCDSIDInput: "Entrez votre identifiant WSLx ou CDS",
    contactUsCDSIDWarning: "Veuillez entrer votre identifiant WSLx ou CDS",
    contactUsSalesCode: "Entrez le code de vente",
    contactUsSalesCodeWarning: "Veuillez entrer un code de vente",
    contactUsEmail: "Entrez votre adresse courriel",
    contactUsDescription:
      "Veuillez décrire votre problème en incluant les principaux éléments (p. ex., le navigateur, s’il fonctionnait auparavant, les étapes que vous avez prises jusqu’ici, etc.). Soyez le plus précis possible.",
    contactUsDescriptionWarning: "Veuillez décrire votre problème",
    adminDashboard: "Tableau de bord de l’administrateur",
    adminDownloadReports: "Télécharger des rapports",
    adminReportDescription:
      "Téléchargez ou affichez un aperçu des rapports sur les véhicules acceptés, refusés et alloués pour chaque période d’offre.",
    adminOfferPeriod: "Période de l’offre",
    adminDownloadReportRow: "Télécharger des rapport",
    permissionLookup: "Recherche de permission",
    permissionCDSPlaceholder: "Identité CDS",
    warningMessageHeader: "MESSAGE URGENT",
    warningMessageText:
      "Si vous avez un <strong>message urgent</strong> à afficher pour <strong>tous</strong> les utilisateurs du portail à leur prochaine connexion, entrez-le ci-dessous",
    warningMessagePlaceholder:
      "Veuillez entrer le message urgent tel qu’il doit s’afficher pour les utilisateurs du portail.",
    warningMessageButton: "Soumettre le message",
    warningMessageMenuLabel: "Régler un message d’avertissement",
    adminMenuLabel: "Admin",
    confirmationEmailWithDefaultEmailPart1:
      "Votre confirmation sera envoyée à ",
    confirmationEmailWithDefaultEmailPart2:
      " ainsi qu’à l’adresse de courriel que vous avez indiquée ci-dessous: ",
    confirmationRequestingEmail:
      "Votre confirmation sera envoyée à l’adresse de courriel que vous avez indiquée ci-dessous:",
    doNotRecognizeEmailLabel:
      "Si vous ne reconnaissez pas cette adresse et que vous souhaitez la modifier, sélectionnez Modifier mon profil dans votre ",
    userProfileLabel: "profil d’utilisateur",
    emailWillBeUpdatedLabel:
      "; l’adresse sera mise à jour lors de votre prochaine connexion au portail.",
    enterEmailHereLabel: "l’adresse de courriel",
    noPermissionForSalesCode:
      "Vous n’avez pas la permission pour soumettre des commandes pour ce code de vente.»",
    offerNotYetStarted: "L’offre n’est pas encore commencée",
    offerStarts: "L’offre débute:",
    dealerOrderWindowClosed:
      "La période de commande des concessionnaires est terminée. Il n’y a aucune offre sur les véhicules en ce moment.",
    noOptionsSelector: "Aucune option",
    noSVRDataFound: "Aucune donnée",
    SVRSuccessModalMessage:
      "Votre demande de véhicules supplémentaires a bien été soumise.",
    SVRSubmitNewRequest: "Soumettre une nouvelle demande",
    SVRGoToHome: "Aller à l’accueil",
    NoVehicleForSalesCode:
      "Le code de vente sélectionné n’est associé à aucun véhicule attribué pour l’offre en cours.",
    ReallocationPeriod:
      "Période de réattribution : la période de l’offre standard est maintenant terminée. Si un véhicule est réattribué à votre concessionnaire durant la période de réattribution, vous pourrez accepter ou rejeter ce véhicule.",
    ReallocationPeriodLabel: "PÉRIODE DE RÉATTRIBUTION",
    LimitedActionPeriod: "La période d’action limitée se termine le :",
    DuringReallocationPeriod:
      "Durant la période de réattribution, tout véhicule réattribué au code de vente sélectionné apparaîtra ici.",
    SelectVehicleLine:
      "Veuillez sélectionner une gamme de véhicules pour voir les offres de véhicules",
    NoCurrentOfferings:
      "Vous n'avez pas d'offres actuelles pour cette gamme de véhicules. Veuillez sélectionner une autre gamme de véhicules.",
    EnterSalesCodeLabel: "Entrez le code de vente pour recevoir le véhicule :",
    ReallocatingLabel: "Réattribution",
    searchButton: "Rechercher",
    vehiclesMustBeRejected:
      "Les véhicules doivent être rejetés avant de pouvoir être réattribués",
    urgentMessageSuccess: "Votre message urgent a bien été soumis.",
    refreshPage: "Veuillez actualiser la page pour voir les modifications.",
    closeButtonLabel: "Fermer",
    deleteConfirmation:
      "Voulez-vous vraiment supprimer le message d’avertissement?",
    confirmationButton: "Oui",
    cancelButton: "Annuler",
    errorModalLabel:
      "Nous sommes désolés, une erreur s’est produite. Veuillez réessayer plus tard.",
    attentionLabel: "ATTENTION",
    contactDealerAdmin:
      "Si vous croyez qu’il s’agit d’une erreur, veuillez communiquer avec l’administrateur de votre concessionnaire.",
    noPastOfferings: "Aucune offre antérieure",
    continueLabel: "Continuer",
    optionalPackagesLabel: "Ensembles en option",
    optionalFeaturesLabel: "Caractéristiques en option",
    lastModifiedBy: "Dernière modification par ",
    onLabel: " le ",
    orderHistoryOf: "Historique des commandes de ",
    orderHistoryFor: " pour la période du ",
    lostSelections:
      "* Vos sélections ne seront pas enregistrées à moins que vous ne cliquiez sur le bouton <b>Approuver</b>. Les changements non enregistrés seront perdus.",
    faqFordAcceptPortal:
      "Qui peut accepter l’allocation dans le portail chez le concessionnaire? Comment puis-je y avoir accès?",
    faqFordFIIAuthority:
      "Les signataires autorisés des concessionnaires doivent accepter le réapprovisionnement rapide et l’allocation de véhicules configurés pour la vente en gros. Les personnes doivent avoir la permission « Order Management: Rapid_Replenishment » pour conclure la vente en gros dans le portail. Veuillez communiquer avec votre administrateur pour obtenir de l’aide.",
    faqFordNoVehicleOfferings:
      "Aucune offre de véhicule ne s’affiche quand j’entre mon code de vente. Qu’est-ce que cela signifie?",
    faqFordDealershipEarned:
      "Le concessionnaire n’a accumulé aucun (0) véhicule de la gamme affichée pendant la période de l’offre. Assurez-vous de vérifier toutes les gammes de véhicules à l’aide du menu déroulant.",
    faqFordAcceptedBeRebilled:
      "Quand refacturera-t-on mes véhicules acceptés à mon concessionnaire?",
    faqFordAcceptedBeRebilledA:
      "Les véhicules acceptés seront refacturés au concessionnaire dans les dix (10) jours ouvrables suivant la fermeture du portail. ",
    faqFordAmendOrders:
      "Puis-je modifier des articles sur mes commandes acceptées?",
    faqFordAmendOrdersA1:
      "Réapprovisionnement rapide – Les commandes deviennent visibles dans WBDO une fois que les véhicules ont été acceptés et refacturés. Les concessionnaires peuvent à ce moment ajuster certaines caractéristiques comme la peinture et l’équipement en option installé par le concessionnaire avant la fin de la période de modification des commandes.",
    faqFordAmendOrdersA2:
      "Véhicules configurés pour la vente en gros – Les commandes deviendront visibles dans WBDO une fois que les véhicules auront été acceptés et refacturés. Les concessionnaires ne pourront plus ajuster les caractéristiques par la suite.",
    faqFordITTroubles:
      "J’ai des problèmes techniques m’empêchant d’accéder au site.",
    faqFordITTroublesA:
      "Veuillez essayer un autre navigateur ou vider la mémoire cache. De plus, seules les personnes pouvant approuver le réapprovisionnement rapide pour la vente en gros (signataires autorisés) peuvent accéder à l’offre. Si vous avez besoin d’assistance supplémentaire, veuillez utiliser la fonction « Communiquez avec nous » dans le menu déroulant du portail.",
    faqFordFindAccepted:
      "Comment puis-je trouver dans WBDO mes véhicules pour lesquels un réapprovisionnement rapide a été accepté?",
    faqFordFindAcceptedA1:
      "Réapprovisionnement rapide – Dans l’onglet des commandes, cliquez sur le menu déroulant « Affichage », puis sélectionnez « Réapprovisionnement rapide ».",
    faqFordFindAcceptedA2:
      "Véhicules configurés pour la vente en gros – Appliquez le filtre Gamme de véhicules par défaut, puis filtrez les résultats associés au code de priorité 80.",
    dealerSelection: "Sélection du concessionnaire",
  },
};

const cvwContentLibrary: {[index: string]: any} = {
  en: {
    orderHistoryPageSubject: "ORDER HISTORY",
    orderHistoryPageDescription:
      "Download reports of accepted, rejected, and allocated vehicles in each offer period.",
    tooltip:
      "Note: Once rebilling of accepted Configured Vehicle Wholesale units has been completed, the orders will become visible in WBDO.",
    title: "Configured Vehicle Wholesale",
    portalTitleText: "CONFIGURED VEHICLE WHOLESALE OFFERS",
    submitModalNote:
      "Note: For Configured Vehicle Wholesale units, no changes to the order will be able to be made.",
    disclaimerAcceptanceLabel:
      "I have read and understand how allocation has been distributed.",
    headerLabel: "CONFIGURED VEHICLE WHOLESALE",
    allocationMethodology:
      "The Rapid Replenishment SVA and 3-month Share of Region formula was utilized to distribute both Bronco Sport and F150 Hybrid CVW allocation.",
    submitButtonTooltipOfferClosed:
      "Configured Vehicle Wholesale offer window is closed",
    permissionsErrorMessage:
      "You do not have permission to submit wholesale orders.",
    contactDealerAdmin:
      "If you think this is an error, please contact your Dealer Administrator.",
    securityAssistance:
      'If you need assistance identifying your Security Provisioning Services (SPS) Administrator, please contact the Business Assistance Center (BAC) at <strong>800-790-4357</strong> or visit the <a style="text-decoration: underline; color: blue" href="https://www.fmcdealer.dealerconnection.com/content/fmcdealer/en/us/Help/wsgchatlaunch.html"> FMCDealer Help Chat</a>.',
    orderGridHelpMessage:
      "If you have further questions, please reach out to your regional Sales and Inventory Specialist.",
    financialResponsibilityLabel:
      "By submitting you are accepting financial responsibility for the vehicles you accepted.",
  },
  fr: {
    submitButtonTooltipOfferClosed:
      "La période de l’offre sur les unités de vente en gros de véhicules configurés est terminée.",
    orderGridHelpMessage:
      "Si vous avez d’autres questions, veuillez communiquer avec votre spécialiste des ventes et des commandes de stockage à l’échelle régionale.",
    disclaimerAcceptanceLabel:
      "J'ai lu et compris la manière dont l'allocation a été répartie.",
  },
};

const lpivwContentLibrary: {[index: string]: any} = {
  en: {
    orderHistoryPageSubject: "ORDER HISTORY",
    orderHistoryPageDescription:
      "Download reports of accepted, rejected, and allocated vehicles in each offer period.",
    tooltip:
      "Produced Import Vehicle Wholesale offerings are produced vehicles and therefore options and features cannot be amended.",
    title: "Lincoln Produced Import Vehicle Wholesale",
    portalTitleText: "PRODUCED IMPORT VEHICLE WHOLESALE OFFERS",
    submitModalNote:
      "Note: For Produced Import Vehicle Wholesale units, no changes to the order will be able to be made.",
    disclaimerAcceptanceLabel:
      "I have read and understand how allocation has been distributed.",
    headerLabel: "PRODUCED IMPORT VEHICLE WHOLESALE",
    allocationMethodology:
      "Lincoln Retailers will earn Produced Import Vehicle Wholesale offerings based on most recent 3 month, month end sales history and SVA.",
    submitButtonTooltipOfferClosed:
      "Produced Import Vehicle Wholesale offer window is closed",
    permissionsErrorMessage:
      "You do not have permission to submit wholesale orders.",
    contactDealerAdmin:
      "If you think this is an error, please contact your Dealer Administrator.",
    securityAssistance:
      'If you need assistance identifying your Security Provisioning Services (SPS) Administrator, please contact the Business Assistance Center (BAC) at <strong>800-790-4357</strong> or visit the <a style="text-decoration: underline; color: blue" href="https://www.fmcdealer.dealerconnection.com/content/fmcdealer/en/us/Help/wsgchatlaunch.html"> FMCDealer Help Chat</a>.',
    orderGridHelpMessage:
      "If you have further questions, please reach out to your Vehicle Sales Operations Lead.",
    financialResponsibilityLabel:
      "By submitting you are accepting financial responsibility for the vehicles you accepted.",
    Question1Label:
      "Who can accept portal based allocation at the retailer? How can I gain access?",
    Answer1Label:
      "Persons with FII authority at the retailer should be accepting Produced Import Vehicle wholesale allocation. Persons must have “Order Management: Rapid_Replenishment” permission to complete the wholesale in the portal. Please contact your administrator for assistance.",
    Question2Label:
      "No vehicle offerings are showing when I enter my sales code. What does this mean?",
    Answer2Label:
      "Retailer earned zero (0) units for the offering period for the displayed vehicle line. Be sure to check all vehicle lines using the vehicle line drop down.",
    Question3Label: "When will my accepted units be rebilled to my retailer?",
    Answer3Label:
      "Accepted units will be rebilled to retailer within ten (10) business days of portal closure. Please check the FMC Dealer Message Centerposting for exact dates.",
    Question4Label: "Can I amend any items on my accepted orders?",
    Answer4Label:
      "Vehicles that are offered in Produced Import Vehicle Wholesale are produced. No amendments may be made on any accepted units.",
    Question5Label: "I am having IT troubles with the site.",
    Answer5Label:
      "Please try another browser or clear your cache. Also, only persons with authority to approve Produced Import Vehicle Wholesale (FII) can access the offering. If further assistance is needed, please utilize the “Contact Us” feature on the portal site via the menu drop down.",
    Question6Label:
      "How can I find my accepted Produced Import Vehicle units in WBDO?",
    Answer6Label:
      "Produced Import Vehicle Wholesaled units are released from the plant and therefore will not display in WBDO.",
  },
  fr: {
    tooltip:
      "Remarque : Une fois que la refacturation des unités de vente en gros de véhicules importés produits est terminée, les commandes deviennent visibles dans l’outil de commandes des concessionnaires sur Internet (WBDO). Aucune modification ne pourra être apportée à la commande. ",
    portalTitleText: "VENTE EN GROS DE VÉHICULES IMPORTÉS PRODUITS",
    submitModalNote:
      "Remarque: Pour les unités de vente en gros de véhicules importés produits, aucune modification ne pourra être apportée à la commande.",
    headerLabel: "VENTE EN GROS DE VÉHICULES IMPORTÉS PRODUITS",
    allocationMethodology:
      "Les détaillants du Comité canadien des produits Nautilus se verront offrir une unité test pour la vente en gros de véhicules importés produits.",
    submitButtonTooltipOfferClosed:
      "La période de l’offre sur les unités de vente en gros de véhicules importés produits est terminée.",
    permissionsErrorMessage:
      "Vous n’avez pas la permission de soumettre des commandes en gros.",
    securityAssistance:
      'Si vous avez besoin d’assistance pour déterminer qui est votre administrateur des Services de sécurité, veuillez communiquer avec le Centre d’Assistance des Affaires au <strong>800-790-4357</strong> ou accéder à la fonctionnalité de clavardage dans <a style="text-decoration: underline; color: blue" href="https://www.fmcdealer.dealerconnection.com/content/fmcdealer/en/us/Help/wsgchatlaunch.html"> FMCDealer pour obtenir de l’aide. </a>',
    orderGridHelpMessage:
      "Si vous avez d’autres questions, veuillez communiquer avec votre Responsable des Opérations, Ventes de Véhicules.",
    disclaimerAcceptanceLabel:
      "J'ai lu et compris comment l'allocation était distribuée.",
    financialResponsibilityLabel:
      "En soumettant votre demande, vous acceptez la responsabilité financière des véhicules que vous avez acceptés.",
    Question1Label:
      "Qui peut accepter l’allocation dans le portail chez le détaillant? Comment puis-je y avoir accès?",
    Answer1Label:
      "Les signataires autorisés des détaillants doivent accepter l’allocation de véhicules importés produits pour la vente en gros. Les personnes doivent avoir la permission « Order Management: Rapid_Replenishment » pour conclure la vente en gros dans le portail. Veuillez communiquer avec votre administrateur pour obtenir de l’aide.",
    Question2Label:
      "Aucune offre de véhicule ne s’affiche quand j’entre mon code de vente. Qu’est-ce que cela signifie?",
    Answer2Label:
      "Le détaillant n’a accumulé aucun (0) véhicule de la gamme affichée pendant la période de l’offre. Assurez-vous de vérifier toutes les gammes de véhicules à l’aide du menu déroulant.",
    Question3Label:
      "Quand refacturera-t-on mes véhicules acceptés à mon détaillant?",
    Answer3Label:
      "Les véhicules acceptés seront refacturés au détaillant dans les dix (10) jours ouvrables suivant la fermeture du portail. Veuillez consulter le Centre de messages dans FMCDealer pour connaître les dates exactes.",
    Question4Label:
      "Puis-je modifier des articles sur mes commandes acceptées?",
    Answer4Label:
      "Les véhicules importés produits offerts pour la vente en gros sont tous produits. Aucune modification ne peut être apportée aux véhicules acceptés.",
    Question5Label:
      "J’ai des problèmes techniques m’empêchant d’accéder au site.",
    Answer5Label:
      "Veuillez essayer un autre navigateur ou vider la mémoire cache. De plus, seules les personnes pouvant approuver les véhicules importés produits en gros (signataires autorisés) peuvent accéder à l’offre. Si vous avez besoin d’assistance supplémentaire, veuillez utiliser la fonction « Communiquez avec nous » dans le menu déroulant du portail.",
    Question6Label:
      "Comment puis-je trouver mes véhicules importés produits dans WBDO?",
    Answer6Label:
      "Les véhicules importés produits vendus en gros quitteront l’usine et ne seront donc pas affichés dans WBDO.",
  },
};

const mewContentLibrary: {[index: string]: any} = {
  en: {
    orderHistoryPageDescription: " ",
    title: "Model E Wholesale",
    portalTitleText: "MODEL E STOCK REPLENISHMENT",
    submitModalNote:
      "Note: The configurations submitted are not guaranteed, but will be on hold pending approval.",
    totalAllowedTitle: "Total Allowed",
    totalAllowedToolTip: "Total number of vehicles allowed on-hand",
    stockTargetTitle: "Stock Guide",
    stockTargetToolTip: "Ideal number of vehicles on lot",
    demoAllowanceTitle: "Demo Allowance",
    demoAllowanceToolTip: "Ford Pro CVC Demo Program Use Only",
    totalAvailableTitle: "Total Available",
    totalAvailableToolTip: "In Production + In Transit + In Stock Units",
    stockBtgTitle: "Stock Replenishment BTG",
    stockBtgToolTip:
      "The number of vehicles you can request for stock (Total Allowed - Ground Stock)",
    MSRPTitle: "MSRP",
    MSRPToolTip:
      "MSRP is Calculated by Base MSRP plus Total of Options excluding Destination and Delivery Charges",
    headerLabel: "MODEL E DEALER STOCK REPLENISHMENT PORTAL",
    ageThresholdTitle:
      "MODEL E STOCK REPLENISHMENT AGE THRESHOLD CONFIGURATION",
    ageSubHeaderText: "Filter by RRC, vehicle line, model year, body, or trim",
    orderHistoryPageSubject: "STOCKING REQUEST HISTORY",
    permissionsErrorMessage:
      "You do not have access to the EV Dealer Stocking Portal.",
    contactDealerAdmin:
      "If you think this is an error, </br></br>" +
      '•\tPlease see your Dealer Administrator (typically one designated at each Dealership) to look up and provide entitlements for a user at: <a style="text-decoration: underline; color: blue" href="https://www.dsps.dealerconnection.com/Sps/index.jsp">https://www.dsps.dealerconnection.com/Sps/index.jsp</a>' +
      "</br></br>" +
      '•\tThe Dealer Administrator can find full information at: <a style="text-decoration: underline; color: blue" href="https://www.ordermanagement2.dealerconnection.com/soms/pdf/Security Setup - US Dealers_Job Aid_Aug 2019.pdf">Security Setup - US Dealers_Job Aid_Aug 2019.pdf (dealerconnection.com)</a>' +
      "</br></br>" +
      "•\tTraining may be provided by your Model e Development Specialist",
    securityAssistance:
      "If Further Questions:" +
      'Please reach out to your Model e Development Specialist or <a style="text-decoration: underline; color: blue" href="mailto:ModelePHQ@fordprogramhq.com">ModelePHQ@fordprogramhq.com</a> ' +
      "(888-850-1002) Monday through Friday, 9:30 AM-7:00 PM ET",
    financialResponsibilityLabel:
      "By approving, you are formally requesting that Ford ship the specified vehicle configurations to you.",
    Question1Label:
      "Which permissions do a dealership employee need to access the EV Dealer Stocking Portal?",
    Answer1Label:
      'Please see your Dealer Administrator (typically one designated at each Dealership) to look up and provide entitlements for a user at: <a style="text-decoration: underline; color: blue" href="https://www.dsps.dealerconnection.com/Sps/index.jsp">https://www.dsps.dealerconnection.com/Sps/index.jsp</a></br></br>•\tThe Dealer Administrator can find full information at: <a style="text-decoration: underline; color: blue" href="https://www.ordermanagement2.dealerconnection.com/soms/pdf/Security Setup - US Dealers_Job Aid_Aug 2019.pdf">Security Setup - US Dealers_Job Aid_Aug 2019.pdf (dealerconnection.com)</a>\n</br>' +
      "•\tTraining may be provided by your Model e Development Specialist",
    Question2Label: "Can I amend any items on my accepted orders?",
    Answer2Label:
      "Model e Vehicles at the RRCs have already been produced and cannot be amended. Additionally, once “Approve” is clicked during the order process, selected configurations, or quantities for the specific order, may no longer be amended.",
    Question3Label: "I am having IT troubles with the site.",
    Answer3Label:
      "It is often hard to tell if there is a connection issue or a true software issue.  Please be sure to check the simple things like Wi-Fi connectivity, clearing your cache or simply switching browsers.\n" +
      "If you still believe there is an issue, please utilize the Contact Us form under the Menu button (top, right hand side of the screen).",
    Question4Label: "How can I find the status of my prior requests?",
    Answer4Label:
      "The Stocking Request History Screen shows the status of recent requests.",
    Question5Label:
      "Can I still order vehicles in WBDO? (2024 F-150 Lightning / 2024 Mustang Mach e)?",
    Answer5Label:
      "<strong><u>24MY Mustang Mach:</u></strong>  Participates in monthly wholesale process and utilizes WBDO for vehicle ordering. Dealerships are responsible for submitting vehicle orders.  </br><strong><u>24MY F-150 Lightning:</u></strong>  Participates in Retail Replenishment Center(RRC) Pilot. Orders are generated by Ford and allocated to each RRC.  Dealers can replenish stock daily via the EV Dealer Stock Portal, fulfilled directly from RRC. ",
    Question6Label:
      "What if I have questions about the stock guide for my dealership?",
    Answer6Label:
      "Contact your Model e Development Specialist. The specialist can walk you through the Dealer Stocking Guide methodology & your dealership’s stocking guide calculation.",
  },
};

const rrContentLibrary: {[index: string]: any} = {
  en: {
    orderHistoryPageSubject: "ORDER HISTORY",
    orderHistoryPageDescription:
      "Download reports of accepted, rejected, and allocated vehicles in each offer period.",
    tooltip:
      "Note: Once rebilling of accepted Rapid Replenishment units has been completed, the orders will become visible in WBDO. At that point, dealers may be able to adjust some features such as paint and dealer installed options before the order amendment window closes.",
    title: "Rapid Replenishment",
    portalTitleText: "RAPID REPLENISHMENT OFFERS",
    submitModalNote:
      "Note: Once rebilling of accepted Rapid Replenishment units has been completed, the orders will become visible in WBDO. At that point, dealers may be able to adjust some features such as paint and dealer installed options before the order amendment window closes.",
    headerLabel: "RAPID REPLENISHMENT",
    submitButtonTooltipOfferClosed:
      "Rapid Replenishment offer window is closed",
    contactDealerAdmin:
      "If you think this is an error, please contact your Dealer Administrator.",
    permissionsErrorMessage:
      "You do not have permission to submit wholesale orders.",
    securityAssistance:
      'If you need assistance identifying your Security Provisioning Services (SPS) Administrator, please contact the FTS Help Desk at <strong>1-800-467-8925</strong> or <a style="text-decoration: underline; color: blue" href="mailto:ics@ford.com">ics@ford.com</a>.',
    orderGridHelpMessage:
      "If you have further questions, please reach out to your regional Sales and Inventory Specialist.",
    financialResponsibilityLabel:
      "By submitting you are accepting financial responsibility for the vehicles you accepted.",
  },
  fr: {
    securityAssistance:
      'Si vous avez besoin d’aide pour trouver votre administrateur des Services de sécurité, veuillez communiquer avec le Centre d’assistance des STF par téléphone au <strong>1 800 429-5527</strong> ou par courriel à l’adresse <a style="text-decoration: underline; color: blue" href="mailto:ics@ford.com">ics@ford.com</a>.',
    contactDealerAdmin:
      "If you think this is an error, please contact your Dealer Administrator.",
    permissionsErrorMessage:
      "Vous n’avez pas la permission de soumettre des commandes en gros.",
    portalTitleText: "OFFRES DE RÉAPPROVISIONNEMENT RAPIDE",
    tooltip:
      "Remarque : une fois que le re-facturation des unités de remplissage rapide acceptées est terminée, les commandes seront visibles dans WBDO. À ce moment-là, les concessionnaires pourront peut-être ajuster certaines fonctionnalités telles que la peinture et les options installées par le concessionnaire avant la fermeture de la fenêtre de modification de la commande.",
    title: "Remplissage rapide",
    headerText: "OFFRES DE REMPLISSAGE RAPIDE",
    submitModalNote:
      "Remarque : Une fois que la refacturation des véhicules pour lesquels un réapprovisionnement rapide a été accepté est terminée, les commandes deviennent visibles dans l’outil de commandes des concessionnaires sur Internet (WBDO). Les concessionnaires peuvent à ce moment ajuster certaines caractéristiques comme la peinture et l’équipement en option installé par le concessionnaire avant la fin de la période de modification des commandes.",
    headerLabel: "REMPLISSAGE RAPIDE",
    submitButtonTooltipOfferClosed:
      "La période de l’offre sur les unités de vente en remplissage rapide est terminée.",
    orderGridHelpMessage:
      "Si vous avez d’autres questions, veuillez communiquer avec votre spécialiste des ventes et des commandes de stockage à l’échelle régionale.",
    financialResponsibilityLabel:
      "En soumettant votre demande, vous acceptez la responsabilité financière des véhicules que vous avez acceptés.",
  },
};

import {getHttpClient} from "./API";
import {ApiUrls} from "../enums/ApiUrls";

export function getLPIVWDisclaimerAccepted() {
  return getHttpClient().get(ApiUrls.lpivwDisclaimerAccepted);
}

export function putLPIVWDisclaimer() {
  return getHttpClient().put(ApiUrls.lpivwDisclaimer);
}

import {getContent} from "../../../service/ContentService";
import ReactTooltip from "react-tooltip";

export function createStockGuideHeader(contentId: string) {
  const toolTipContent = getContent(`${contentId}ToolTip`);
  const titleContent = getContent(`${contentId}Title`);
  return (
    <div className={"tool-tip"} data-testid={`${contentId}-tooltip`}>
      <ReactTooltip
        place={"top"}
        effect={"solid"}
        multiline={true}
        id={`${contentId}-tooltip`}
        class={"react-tooltip"}
      />
      <span
        className={"tool-tip"}
        data-testid={"tool-tip-span"}
        data-tip={toolTipContent}
        data-for={`${contentId}-tooltip`}
        aria-label={titleContent}>
        <p className={"stock-guide-column-header"}>{titleContent}</p>
      </span>
    </div>
  );
}

export function createMSRPHeader(contentId: string) {
  const toolTipContent = getContent(`${contentId}ToolTip`);
  const titleContent = getContent(`${contentId}Title`);
  return (
    <div data-testid={`${contentId}-tooltip`}>
      <ReactTooltip
        place={"top"}
        effect={"solid"}
        multiline={true}
        id={`${contentId}-tooltip`}
        class={"react-tooltip"}
      />
      <span
        data-testid={"tool-tip-span"}
        data-tip={toolTipContent}
        data-for={`${contentId}-tooltip`}
        aria-label={titleContent}>
        {titleContent}
      </span>
    </div>
  );
}

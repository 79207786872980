import "../../styles/AddToSelectionButton.scss";
import {ModelEConfigurationStatus} from "../../enums/ModelEConfigurationStatus";

enum SelectionButtonState {
  DEFAULT = "Select",
  SELECTED = "Selected",
  ON_HOLD = "Requested",
  ACCEPTED = "Approved",
}

const SelectionButtonStateMap = new Map<
  ModelEConfigurationStatus,
  SelectionButtonState
>([
  [ModelEConfigurationStatus.ON_HOLD, SelectionButtonState.ON_HOLD],
  [ModelEConfigurationStatus.ACCEPTED, SelectionButtonState.ACCEPTED],
]);

type AddToSelectionButtonProps = {
  configurationId: string;
  rowKey: string;
  configurationStatus: ModelEConfigurationStatus;
  userSelected: boolean;
  onClick: (configurationId: string) => void;
  disabled?: boolean;
};

export function AddToSelectionButton(props: AddToSelectionButtonProps) {
  const handleAddToSelectionClick = async () => {
    props.onClick(props.rowKey);
  };
  function getButtonState(): SelectionButtonState {
    switch (props.configurationStatus) {
      case ModelEConfigurationStatus.NO_ACTION:
        return props.userSelected
          ? SelectionButtonState.SELECTED
          : SelectionButtonState.DEFAULT;
      default:
        const buttonState = SelectionButtonStateMap.get(
          props.configurationStatus
        );
        return buttonState !== undefined
          ? buttonState
          : SelectionButtonState.DEFAULT;
    }
  }

  function getSelectionStateClassName(): string {
    switch (getButtonState()) {
      case SelectionButtonState.ON_HOLD:
        return "fmc-button--on_hold";
      default:
        return "";
    }
  }

  return (
    <button
      className={`fmc-button ${getSelectionStateClassName()}`}
      data-testid={`select-button-${props.rowKey}`}
      aria-label={"Select Configuration Button"}
      onClick={handleAddToSelectionClick}
      disabled={props.disabled}>
      {getButtonState()}
    </button>
  );
}

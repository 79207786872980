import {
  buildConfigurationGridData,
  ConfigurationGridData,
  MSRPWithDetails,
} from "../types/ConfigurationGridData";
import {Optionality} from "../enums/Optionality";
import {ModelEConfigurationStatus} from "../enums/ModelEConfigurationStatus";
import {State} from "../enums/State";

export function enumeratedConfigurationToConfigurationGridData(
  enumeratedVehicleConfiguration: EnumeratedVehicleConfiguration[]
): ConfigurationGridData[] {
  return enumeratedVehicleConfiguration
    .filter(
      (ele) =>
        ele.selectionCount.NO_ACTION > 0 || ele.selectionCount.ON_HOLD > 0
    )
    .flatMap((configuration: EnumeratedVehicleConfiguration) => {
      const gridData: ConfigurationGridData[] = [];
      if (configuration.selectionCount.NO_ACTION > 0) {
        gridData.push(
          buildConfigurationGridData(
            configuration,
            configuration.selectionCount.NO_ACTION,
            ModelEConfigurationStatus.NO_ACTION
          )
        );
      }
      if (configuration.selectionCount.ON_HOLD > 0) {
        gridData.push(
          buildConfigurationGridData(
            configuration,
            configuration.selectionCount.ON_HOLD,
            ModelEConfigurationStatus.ON_HOLD
          )
        );
      }
      return gridData;
    })
    .sort((left, right) => {
      if (left.selection === ModelEConfigurationStatus.ON_HOLD) {
        return -1;
      } else if (right.selection === ModelEConfigurationStatus.ON_HOLD) {
        return 1;
      }
      return 0;
    });
}

export interface ConfigurationFeature {
  wersCode: string;
  description: string;
  optionality: Optionality;
  state: State;
  msrp: number | null;
}

export interface VehicleConfigurationOrder {
  key: string;
  body: ConfigurationFeature;
  code: ConfigurationFeature;
  paint: ConfigurationFeature;
  trim: ConfigurationFeature;
  packages: ConfigurationFeature[];
  options: ConfigurationFeature[];
  dealerInstalledOptions: ConfigurationFeature[];
  vehicleLine: string;
  msrp: number;
  msrpWithDetails: MSRPWithDetails;
  engine: ConfigurationFeature;
}

export interface EnumeratedVehicleConfiguration {
  vehicleConfigurationOrder: VehicleConfigurationOrder;
  selectionCount: {
    ACCEPTED: number;
    REJECTED: number;
    NO_ACTION: number;
    REALLOCATED: number;
    RESCINDED: number;
    ON_HOLD: number;
    REMOVED: number;
  };
}

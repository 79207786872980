export enum ApiUrls {
  getReallocateSalesCodes = "/api/dealers",
  getSalesCodes = "/api/salesCodes",
  getLincolnSalesCodes = "/api/lincoln/salesCodes",
  featureFlags = "/api/featureFlags",
  hasReadAllPermission = "/api/hasReadAllPermission",
  hasAdminPermission = "/api/hasAdminPermission",
  timeRemaining = "/api/offerTime/timeRemaining",
  exportFullReport = "/api/exportFullReport",
  orders = "/api/orders/",
  reallocate = "/api/reallocate",
  dealerSelection = "/api/dealerSelection/",
  hasSubmitPermission = "/api/hasSubmitPermission",
  dealerIssue = "/api/dealerIssue",
  archiveOrders = "/api/offering/archive",
  warningMessage = "/api/warningMessage",
  getVehicleLineConfigurations = "/api/offering/configurations",
  cvwDisclaimerAccepted = "/api/user/cvwDisclaimerAccepted",
  createSupplementalVehicles = "/api/supplementalVehicles",
  availableVehicleLines = "/api/activeVehicleLines",
  availableVehicleBodyDescriptions = "/api/offering/availableVehicleBodyDescriptions",
  supplementalVehiclesCSV = "/api/supplementalVehicles/csv",
  exportRebillingReport = "/api/exportRebillingReport",
  regions = "/api/region",
  completedOfferings = "/api/offering/completed",
  completedAndActiveOfferings = "/api/offering",
  availableVehicleConfigurationByPurchaseList = "/api/ordersByConfigurations",
  orderHistoryBy = "/api/report/orderHistory",
  lpivwDisclaimer = "/api/lincoln/disclaimer/",
  lpivwDisclaimerAccepted = "/api/lincoln/disclaimer/accepted",
  modelEOrder = "/api/modelE/order",
  loadRrcConfigurations = "/api/rrcConfigurations",
  updateRrcConfigurations = "/api/rrcConfigurations",
  getAllVehicleLineDescriptions = "/api/descriptions/",
}

import {Route, Routes} from "react-router-dom";
import {Home} from "../pages/home/Home";
import {Login} from "../pages/login/Login";
import {OauthCallback} from "../components/login/OauthCallback";
import {OrderHistory} from "../pages/orderHIstory/OrderHistory";
import {FAQ} from "../pages/faq/FAQ";
import {AdminPage} from "../pages/admin/AdminPage";
import React from "react";
import {PageNotFound} from "../pages/PageNotFound";
import {ReplenishmentAge} from "../pages/replenishmentAge/ReplenishmentAge";

export function ModelEOfferingRoutes() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/oauth-callback" element={<OauthCallback />} />
        <Route path="/history" element={<OrderHistory />} />
        <Route path="/replenishment-age" element={<ReplenishmentAge />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/admin" element={<AdminPage />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
}

import {getHttpClient} from "./API";
import {ApiUrls} from "../enums/ApiUrls";
import {SessionStorageKeys} from "../enums/SessionStorageKeys";

export class FeatureFlagService {
  public static setFeatureFlags() {
    getHttpClient()
      .get(ApiUrls.featureFlags)
      .then((response) => {
        sessionStorage.setItem(
          SessionStorageKeys.FEATURE_FLAGS,
          JSON.stringify(response.data)
        );
      });
  }

  public static getFeatureFlag(myFeatureFlag: string) {
    const localFeatureFlag = localStorage.getItem(myFeatureFlag);
    if (localFeatureFlag) {
      return localFeatureFlag === "true";
    }

    const featureFlags = sessionStorage.getItem(
      SessionStorageKeys.FEATURE_FLAGS
    );

    if (featureFlags) {
      const featureFlagsArray: string[] = JSON.parse(featureFlags);
      return featureFlagsArray.includes(myFeatureFlag);
    }
    return false;
  }
}

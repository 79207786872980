import "../../../styles/Home.scss";
import {ListItemIcon, ListItemText, MenuItem} from "@mui/material";
import * as React from "react";
import {getIcon, IconName} from "../../../service/iconFinderService";
import {getContent} from "../../../service/ContentService";
import {useGlobalState} from "../../../state/GlobalState";

export function ContactUsButton(props: {
  setContactUsVisible: (arg0: boolean) => void;
}) {
  const [currentLanguage] = useGlobalState("language");

  // @ts-ignore
  return (
    <>
      <MenuItem
        onClick={() => {
          props.setContactUsVisible(true);
        }}>
        <ListItemIcon>
          <img
            src={getIcon(IconName.ContactUs)}
            className="menu-button-icons"
            alt={"contact us menu button"}
          />
        </ListItemIcon>
        <ListItemText>
          {getContent("contactUsMenuLabel", currentLanguage)}
        </ListItemText>
      </MenuItem>
    </>
  );
}

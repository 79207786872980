import {useGlobalState} from "../../state/GlobalState";
import React, {useEffect, useState} from "react";
import {DisplayableSelectionStatus} from "../../enums/DisplayableSelectionStatus";
import {FormattedOrder} from "../../types/order";
import "../../styles/OfferingStatus.scss";
import {getSkin, Skin} from "../../service/SkinService";
import {getContent} from "../../service/ContentService";

export function OfferingStatus() {
  const [data] = useGlobalState("orderGridData");
  const [currentLanguage] = useGlobalState("language");
  const [totalVehicles, setTotalVehicles] = useState(0);
  const [totalAccepted, setTotalAccepted] = useState(0);
  const [totalRejected, setTotalRejected] = useState(0);

  useEffect(() => {
    setTotalVehicles(data.length);
    setTotalAcceptedAndRejected();
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  function setTotalAcceptedAndRejected() {
    let accepted = 0;
    let rejected = 0;

    data.forEach((order: FormattedOrder) => {
      if (order.formattedSelection === DisplayableSelectionStatus.Accepted)
        accepted++;
      else if (order.formattedSelection === DisplayableSelectionStatus.Rejected)
        rejected++;
    });

    setTotalAccepted(accepted);
    setTotalRejected(rejected);
  }

  const skin = getSkin();
  return (
    <div
      data-testid={"offering-status"}
      className={
        skin === Skin.LPIVW
          ? "offering-status lincoln-table-box-header"
          : "offering-status table-box-header"
      }>
      <div>
        <h6 className={"offering-status-heading"}>
          {getContent("offeringStatusLabel", currentLanguage)}
        </h6>
      </div>
      <div className={"offering-grid"}>
        <div className={"vehicle-response-count"}>{totalAccepted}</div>
        <sup>of {totalVehicles}</sup>
        <div className={"offering-wording"}>
          {getContent("acceptedLabel", currentLanguage)}
        </div>
        <div className={"vehicle-response-count"}>{totalRejected}</div>
        <sup>of {totalVehicles}</sup>
        <div className={"offering-wording"}>
          {getContent("rejectedLabel", currentLanguage)}
        </div>
      </div>
    </div>
  );
}

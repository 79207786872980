import home from "../../images/home.svg";
import * as React from "react";
import {getContent} from "../../service/ContentService";
import {useGlobalState} from "../../state/GlobalState";

export function HomeLink() {
  const [currentLanguage] = useGlobalState("language");
  const homeText = getContent("homeText", currentLanguage);

  return (
    <a className={"white-font"} href={"/"} aria-label={"link-to-home"}>
      {homeText}
      <img className={"home-logo"} src={home} alt={"Home"} />
    </a>
  );
}

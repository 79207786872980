import React from "react";

export function SuccessfulRequestModal(props: {onClick: () => void}) {
  return (
    <div
      className={"fmc-dialog fds-align--center fmc-dialog--opened"}
      role={"dialog"}
      aria-modal={true}
      aria-label={"Request Successful"}>
      <div className={"fmc-dialog__content"}>
        <button
          className="fmc-dialog__close fds-icon fds-font--ford-icons__clear"
          aria-label="A close button."
          onClick={props.onClick}></button>
        <div className="modal-header" data-testid="successfulRequestModal">
          <h3>Your request was received successfully!</h3>
          <p className="fmc-type--subtitle1 fds-m--b-2">
            Please check your email for your request confirmation.
          </p>
        </div>
        <div className={"fds-m--t-3"}>
          <button
            className="fmc-button fds-m--b-2"
            aria-label="close order request modal"
            onClick={props.onClick}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
}

import {useState} from "react";
import {useGlobalState} from "../../state/GlobalState";
import "../../styles/SubmitButton.scss";
import {getHttpClient} from "../../service/API";
import {SubmitButton} from "./SubmitButton";
import {SubmitModal} from "./SubmitModal";
import {ApiUrls} from "../../enums/ApiUrls";
import {SessionStorageKeys} from "../../enums/SessionStorageKeys";

export function SubmitOfferings() {
  const [userInputEmail, setUserInputEmail] = useState<string | null>(null);
  const [modalVisible, setModalVisibility] = useState(false);
  const [dealerSelectionList, setDealerSelectionList] = useGlobalState(
    "dealerSelectionList"
  );
  const [selectedSalesCode] = useGlobalState("salesCode");
  const [submissionMade, setSubmissionMade] = useGlobalState("submissionMade");

  function confirmSubmission() {
    setModalVisibility(false);
    getHttpClient()
      .post(ApiUrls.dealerSelection + selectedSalesCode, {
        dealerSelectionRequests: dealerSelectionList,
        emailAddresses: [
          sessionStorage.getItem(SessionStorageKeys.EMAIL),
          userInputEmail,
        ],
      })
      .then((response) => {
        if (response.status === 200) {
          setDealerSelectionList([]);
          setSubmissionMade(!submissionMade);
        }
      });
  }

  return (
    <div>
      {modalVisible && (
        <SubmitModal
          setModalVisibilityFalse={() => setModalVisibility(false)}
          setUserInputEmail={setUserInputEmail}
          userInputEmail={userInputEmail}
          confirmSubmission={confirmSubmission}
        />
      )}

      <SubmitButton setModalVisibilityTrue={() => setModalVisibility(true)} />
    </div>
  );
}

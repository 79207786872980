import {useGlobalState} from "../../state/GlobalState";
import "../../styles/ReallocationGuide.scss";
import {getContent} from "../../service/ContentService";

export function ReallocationGuide() {
  const [offeringWindowOngoing] = useGlobalState("offeringWindowOngoing");
  const [currentLanguage] = useGlobalState("language");
  const [reallocationPeriodOngoing] = useGlobalState(
    "reallocationPeriodOngoing"
  );

  if (!offeringWindowOngoing && reallocationPeriodOngoing) {
    return (
      <div
        data-testid={"reallocation-guide-text"}
        className={"reallocation-guide"}>
        {getContent("ReallocationPeriod", currentLanguage)}
      </div>
    );
  }

  return <></>;
}

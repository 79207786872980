import {getContent} from "../../service/ContentService";
import "../../styles/lincoln/lincolnAllocationMethodology.scss";
import {useGlobalState} from "../../state/GlobalState";

export function LincolnAllocationMethodology() {
  const [currentLanguage] = useGlobalState("language");
  const allocationMethodologyHeader = getContent(
    "allocationMethodologyLabel",
    currentLanguage
  );
  const allocationMethodologyText = getContent(
    "allocationMethodology",
    currentLanguage
  );
  return (
    <div className={"lincoln-allocation-methodology"}>
      <p className={"lincoln-allocation-header"}>
        {allocationMethodologyHeader}
      </p>
      <div>
        <span className={"orange-line"}></span>
        <span className={"lincoln-allocation-text"}>
          {allocationMethodologyText}
        </span>
      </div>
    </div>
  );
}

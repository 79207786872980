import {ApiUrls} from "../enums/ApiUrls";
import {getHttpClient} from "./API";

export const isUserAdmin = async () => {
  const httpClient = getHttpClient();
  return httpClient.get(ApiUrls.hasAdminPermission);
};

export const isUserReadAll = async () => {
  const httpClient = getHttpClient();
  return httpClient.get(ApiUrls.hasReadAllPermission);
};

export function hasSubmitPermissionsFor(salesCode: string) {
  return getHttpClient().get(`${ApiUrls.hasSubmitPermission}/${salesCode}`);
}

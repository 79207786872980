import Select from "react-select";
import {useGlobalState} from "../../state/GlobalState";
import "../../styles/RebillingTooltip.scss";
import {getAvailableVehicleLines} from "../../service/OfferingService";
import {useEffect, useState} from "react";
import {getSkin, Skin} from "../../service/SkinService";
import {getContent} from "../../service/ContentService";

interface formattedVehicleLine {
  value: string;
  label: string;
}

export function VehicleLineSelector(props: {salesCode: string}) {
  const [vehicleLine, setVehicleLine] = useGlobalState("vehicleLine");
  const salesCode = props.salesCode;
  const [currentLanguage] = useGlobalState("language");
  const [vehicleLineOptions, setVehicleLineOptions] = useState(
    [] as formattedVehicleLine[]
  );

  useEffect(() => {
    getAvailableVehicleLines().then((vehicleLines) => {
      setVehicleLineOptions(formatVehicleLineOptions(vehicleLines));
    });
  }, []);

  function formatVehicleLineOptions(vehicleLineOptions: string[]) {
    if (getSkin() === Skin.MEW) {
      return vehicleLineOptions?.map(
        (element: string): formattedVehicleLine => {
          return {
            value: element,
            label: element === "F-150" ? "Lightning" : element,
          };
        }
      );
    } else {
      return vehicleLineOptions?.map(
        (element: string): formattedVehicleLine => {
          return {
            value: element,
            label: element === "2024 MKX-Nautilus" ? "2024 Nautilus" : element,
          };
        }
      );
    }
  }

  const isSalesCodeSelected = () => salesCode !== "";

  return (
    <div
      className={"fmc-top-banner--warning salesCodeContainer"}
      data-testid={"vehicle-line-container"}>
      <div>
        <form data-testid="vls-select" style={{minWidth: "250px"}}>
          <label className="hidden" htmlFor="vehicleLineOptions">
            Vehicle Lines
          </label>
          <Select
            className="sales-code-dropdown"
            options={vehicleLineOptions}
            name="vehicleLineOptions"
            inputId="vehicleLineOptions"
            id="vehicleLineSelector"
            placeholder={getContent(
              "selectVehicleLinePlaceholderText",
              currentLanguage
            )}
            onChange={(event: any) => setVehicleLine(event.value)}
            isDisabled={!isSalesCodeSelected()}
            noOptionsMessage={() =>
              getContent("noOptionsSelector", currentLanguage)
            }
          />
        </form>
      </div>
      {vehicleLine === "" && (
        <div className={"salesCodeSelectWarning"}>
          <div
            className={
              getSkin() === Skin.LPIVW ? "lincoln-warning-icon" : "warning-icon"
            }>
            <span
              className={
                getSkin() === Skin.LPIVW
                  ? "fds-icon fds-icon--22 fds-font--ford-icons__warning-filled"
                  : "fds-icon fds-icon--22 fds-font--ford-icons__warning-filled"
              }></span>
            <span>
              &nbsp;{" "}
              {getContent("selectVehicleLineWarningMessage", currentLanguage)}{" "}
              &nbsp;
            </span>
          </div>
        </div>
      )}
    </div>
  );
}

import {getFirstName} from "../../service/AuthService";
import {useGlobalState} from "../../state/GlobalState";
import {getContent} from "../../service/ContentService";

export function Greetings() {
  const [currentLanguage] = useGlobalState("language");
  return (
    <div className={"greetings"}>
      {getContent("greetingText", currentLanguage)}
      {getFirstName()}
    </div>
  );
}

import {CSVLink} from "react-csv";
import {setGlobalState, useGlobalState} from "../../state/GlobalState";
import {LegacyRef, useRef, useState} from "react";
import {getFullReportData} from "../../service/ReportService";
import {getContent} from "../../service/ContentService";

export const ExportFullReport = () => {
  const [hasReadAllPermissions] = useGlobalState("hasReadAllPermissions");
  const [csvData, setCsvData] = useState<string>("");
  const csvDownloadLink: LegacyRef<any> = useRef();
  const [fileName, setFileName] = useState("");
  const [currentLanguage] = useGlobalState("language");

  const getSelectionReportData = () => {
    setGlobalState("downloadModalVisible", true);
    getFullReportData().then((response) => {
      setCsvData(response.data);
      setFileName(
        response.headers["content-disposition"]
          .split("=")[1]
          .replaceAll('"', "")
      );
      if (csvDownloadLink.current) csvDownloadLink.current.link.click();
    });
  };

  return (
    <>
      {hasReadAllPermissions && (
        <div>
          <button
            className={"fmc-button admin-tool-button"}
            data-testid={"export-full-report"}
            onClick={getSelectionReportData}>
            {getContent("exportFullReportButton", currentLanguage)}
          </button>
          <CSVLink
            data-testid={"download-link"}
            data={csvData}
            filename={fileName}
            ref={csvDownloadLink}
            target="_blank"
          />
        </div>
      )}
    </>
  );
};

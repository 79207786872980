import {getHttpClient} from "./API";
import {ApiUrls} from "../enums/ApiUrls";

import {EnumeratedVehicleConfiguration} from "../models/Configuration";
import {SessionStorageKeys} from "../enums/SessionStorageKeys";

export interface DealerConfigurationRequest {
  configurationId: string;
  amountRequested: number;
}

export async function submitDealerConfigurationRequestByPurchaseList(
  salesCode: string,
  dealerConfigurationRequests: DealerConfigurationRequest[],
  userInputEmail?: string | null
) {
  let response = await getHttpClient().post(
    ApiUrls.availableVehicleConfigurationByPurchaseList + "/" + salesCode,
    {
      dealerConfigurationRequests: dealerConfigurationRequests,
      emailAddresses: [
        sessionStorage.getItem(SessionStorageKeys.EMAIL),
        userInputEmail,
      ],
    }
  );
  return response;
}

export async function getCurrentModelEConfigurations(
  salesCode: string | null
): Promise<EnumeratedVehicleConfiguration[]> {
  let requestUrl: string = ApiUrls.availableVehicleConfigurationByPurchaseList;
  if (salesCode != null) {
    requestUrl = requestUrl + `/${salesCode}`;
  }
  const response = await getHttpClient().get(requestUrl);
  return response.data;
}

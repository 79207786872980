import {useState} from "react";
import Modal from "react-modal";
import "../../styles/WarningMessage.scss";
import {useIsMounted} from "./useIsMounted";
import {getSkin, Skin} from "../../service/SkinService";
import {getContent} from "../../service/ContentService";
import {useGlobalState} from "../../state/GlobalState";

export function AreYouSureButton(props: {
  confirmAction: () => Promise<void>;
  buttonLabel: string | JSX.Element;
  className?: string;
  confirmationQuestion: string;
}) {
  const isMounted = useIsMounted();
  const [isVisible, setIsVisible] = useState(false);
  const [currentLanguage] = useGlobalState("language");

  const closeModal = () => {
    if (isMounted()) {
      setIsVisible(false);
    }
  };

  const skin = getSkin();

  return (
    <>
      <Modal isOpen={isVisible} ariaHideApp={false} onRequestClose={closeModal}>
        <div>
          <button
            className="fmc-dialog__close fds-icon fds-font--ford-icons__clear close-button"
            data-testid="close-warning-message-modal"
            aria-label={"Close warning modal"}
            onClick={closeModal}></button>
          <br />
        </div>
        <div
          data-brand={skin === Skin.LPIVW ? "lincoln" : "ford"}
          className={
            skin === Skin.LPIVW
              ? "lpivw-font-family__proxima-nova confirmation-message-container"
              : "confirmation-message-container"
          }>
          <div>{props.confirmationQuestion}</div>
          <div>
            <button
              className="fmc-button confirmation-button"
              onClick={() => {
                props
                  .confirmAction()
                  .then(closeModal)
                  .catch(() => {});
              }}>
              {getContent("confirmationButton", currentLanguage)}
            </button>
            <button
              className="fmc-button fmc-button--outlined"
              onClick={closeModal}>
              {getContent("cancelButton", currentLanguage)}
            </button>
          </div>
        </div>
      </Modal>
      <button
        onClick={() => {
          setIsVisible(true);
        }}
        className={props.className}>
        {props.buttonLabel}
      </button>
    </>
  );
}

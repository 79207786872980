import {getContent} from "../../service/ContentService";
import {RRTooltip} from "../header/RRTooltip";
import React from "react";
import {useGlobalState} from "../../state/GlobalState";

export function PortalTitle() {
  const [currentLanguage] = useGlobalState("language");

  return (
    <div className="portal-header">
      <div className={"title-and-tooltip"}>
        <h1 className="portal-name">
          {getContent("portalTitleText", currentLanguage)}
        </h1>
        <RRTooltip contentId="tooltip" image={true} />
      </div>
    </div>
  );
}

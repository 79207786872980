import {getHttpClient} from "./API";
import {OfferWindow} from "../models/OfferWindow";
import {ApiUrls} from "../enums/ApiUrls";

const getOfferWindow = () => {
  let httpClient = getHttpClient();
  let offerWindow: OfferWindow = {
    startTime: "",
    endTime: "",
    reallocationEndTime: "",
  };

  return httpClient
    .get(ApiUrls.timeRemaining)
    .then(function (response) {
      offerWindow.startTime = new Date(response.data?.startTime);
      offerWindow.endTime = new Date(response.data?.endTime);
      offerWindow.reallocationEndTime = new Date(
        response.data?.reallocationEndTime
      );
      return offerWindow;
    })
    .catch();
};

export {getOfferWindow};

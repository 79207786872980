import React, {useEffect, useState} from "react";

type SubmitSelectionButtonProps = {
  selectionState: {};
  onSubmitClick: () => void;
  buttonText: string;
};

export function SubmitSelectionButton(props: SubmitSelectionButtonProps) {
  let [buttonIsEnabled, setButtonIsEnabled] = useState(false);

  useEffect(() => {
    setButtonIsEnabled(isFieldTrueAnywhere(props.selectionState));
  }, [props.selectionState]);

  function isFieldTrueAnywhere(obj: {
    [x: string]: {[x: string]: boolean};
    hasOwnProperty?: any;
  }) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key]["selected"] || obj[key]["rrc"]) {
          return true;
        }
      }
    }
    return false;
  }

  return (
    <button
      className={"fmc-button submit-button"}
      aria-label={"Submit Selection Button"}
      disabled={!buttonIsEnabled}
      onClick={props.onSubmitClick}>
      {props.buttonText}
    </button>
  );
}

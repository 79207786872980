"use client";
import {useEffect} from "react";
import {getSkin, Skin} from "../../service/SkinService";

interface MyWindow extends Window {
  _mfq?: any[];
}

declare var window: MyWindow;

export default function MouseFlow() {
  useEffect(() => {
    window._mfq = window._mfq || [];

    let skin = getSkin();
    let source;
    if (skin === Skin.CVW) {
      source =
        "//cdn.mouseflow.com/projects/32e8599e-d7c5-4f4c-8308-674dd6b7ae92.js";
    } else if (skin === Skin.LPIVW) {
      source =
        "//cdn.mouseflow.com/projects/3b2f3c11-8643-42b0-a22b-d45e6ed315f1.js";
    } else if (skin === Skin.MEW) {
      source =
        "//cdn.mouseflow.com/projects/c6ad144f-8c83-4ada-ab69-f7b23c28f11c.js";
    } else {
      source =
        "//cdn.mouseflow.com/projects/8670c85f-d534-43cc-a1b8-e26e14292ade.js";
    }

    (function () {
      var mf = document.createElement("script");
      mf.type = "text/javascript";
      mf.defer = true;
      mf.src = source;
      document.getElementsByTagName("head")[0].appendChild(mf);
    })();
  }, []);

  return <></>;
}
